
import OverviewImg from "../assets/img/pages/Mockup1_overview.png";
import TransactionsImg from "../assets/img/pages/Mockup1_transactions.png";
import BalanceImg from "../assets/img/pages/Mockup1_balance.png";
import StakingImg from "../assets/img/pages/Mockup1_staking.png";
import TaxReportImg from "../assets/img/pages/Mockup1_taxReport.png";
import PositionsImg from "../assets/img/pages/Mockup1_positions.png";

import { Routes } from "../routes";


export default [
    {
        "id": 1,
        "name": "Overview",
        "image": OverviewImg,
        "link": Routes.DashboardOverview.path
    },
    {
        "id": 2,
        "name": "Transactions",
        "image": TransactionsImg,
        "link": Routes.Transactions.path
    },
    {
        "id": 6,
        "name": "Balance",
        "image": BalanceImg,
        "link": Routes.Balances.path
    },
    {
        "id": 9,
        "name": "Staking",
        "image": StakingImg,
        "link": Routes.Staking.path
    },
    {
        "id": 10,
        "name": "Trades",
        "image": TaxReportImg,
        "link": Routes.TaxReport.path
    }
    ,
    {
        "id": 11,
        "name": "Positions",
        "image": PositionsImg,
        "link": Routes.TaxFifos.path
    }
];