import React, { useContext } from 'react'
import { UserContext } from "../UserContext"
import { useAuth0 } from "@auth0/auth0-react"
import { Button } from '@themesberg/react-bootstrap';

export default function MyAuth0Login() {

  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();

  return !isAuthenticated && (
      <Button onClick={() => loginWithRedirect()}  variant="primary" className="m-1 btn-sm">Login / Sign Up</Button>
  )
}