import React, { useState } from "react";
import useRupineAPI from "./RupineAPI";
import { remove } from "lodash";

export default function useHasuraAPI() {
    
    //const deploymentStage = 'dbdev';
    //const deploymentStage = 'stage';
    const deploymentStage = 'v1';
    
    //const stageGraphQL = 'dbdev';
    //const stageGraphQL = 'stage';
    const stageGraphQL = 'prod';

    const { getDataAuthorized, fetchAuthorized, fetchAuthorizedBody } = useRupineAPI();

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const uploadCEXCSV = async (account_name, fileName, fileType, csv_type, base64str) => {

        var url = 'https://wealthy-turtle-90.hasura.app/v1/graphql'; 

        const FILE_UPLOAD_MUTATION = `
        mutation FileUpload($account_name: String = "", $base64str: String = "", $name: String = "", $type: String = "", $csv_type: Int = 0) {
            act_` + stageGraphQL  + `_file_upload(account_name: $account_name, base64str: $base64str, name: $name, type: $type, csv_type: $csv_type) {
              id
            }
          }
        `;

        const variables = {account_name: account_name, name: fileName, type: fileType, base64str: base64str, csv_type: parseInt(csv_type)};
        
        const body = JSON.stringify({
            query: FILE_UPLOAD_MUTATION,
            variables: variables
          })
          
        //console.log(url)
        //console.log(body)
        
        var responseData = await fetchAuthorizedBody(url, body)
        //console.log(responseData)
 
        if (responseData != null){
            return responseData;
        }
        return null;
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const getUploads = async () => {

        var url = 'https://wealthy-turtle-90.hasura.app/v1/graphql'; 

        const HASURA_QUERY = `
        query GetUploads {
            ` + stageGraphQL + `_dwh_uploads {
              account_name
              account_no
              filename
              filetype
              modified_at
              created_at
              id
            }
            ` + stageGraphQL + `_dwh_uploads_aggregate {
              aggregate {
                count
              }
            }
          }
        `;

        const variables = {};
        
        const body = JSON.stringify({
            query: HASURA_QUERY,
            variables: variables
          })
          
        var responseData = await fetchAuthorizedBody(url, body)
        //console.log(responseData)

        if (responseData != null){
            if( deploymentStage == 'dbdev'){
                return {
                    files: responseData.data.dbdev_dwh_uploads,
                    aggregate: responseData.data.dbdev_dwh_uploads_aggregate.aggregate
                }
            }

            if( deploymentStage == 'stage'){
                return {
                    files: responseData.data.stage_dwh_uploads,
                    aggregate: responseData.data.stage_dwh_uploads_aggregate.aggregate
                }
            }

            if( deploymentStage == 'v1'){
                return {
                    files: responseData.data.prod_dwh_uploads,
                    aggregate: responseData.data.prod_dwh_uploads_aggregate.aggregate
                }
            }
        }

        return null;
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const addWallet = async (chain_id, public_address, comment, is_business, wallet_friendly_name) => {

        var url = 'https://wealthy-turtle-90.hasura.app/api/rest/' + deploymentStage + '/addAccount/' + chain_id + '/' 
        + public_address  + '/' + comment  + '/' + is_business  + '/' + wallet_friendly_name  + '/'; 

        var response = await fetchAuthorized(url)
        //console.log(response)
 
        // return true when adding was successfull
        try{
            if (response['act_prod_add_account']['id'] > 0){
                return true;
            }
        } catch(e){}

        return false;
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const removeWallet = async (removeId) => {

        var url = 'https://wealthy-turtle-90.hasura.app/api/rest/' + deploymentStage + '/deleteAccount/' + removeId
        var response = await fetchAuthorized(url)
        
        if (response && response.status == 200){
            return true;
        }

        return false;
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const getWalletData = async (limit, offset) => {
        var walletData = await getDataAuthorized('https://wealthy-turtle-90.hasura.app/api/rest/' + deploymentStage + '/getAccounts/' + limit + '/' + offset);

        if(walletData == null ) { 
            return null
        }

        if( deploymentStage == 'dbdev'){
            return {
                address_account: walletData.dbdev_v_dwh_address_account,
                aggregate: walletData.dbdev_v_dwh_address_account_aggregate.aggregate
            }
        }

        if( deploymentStage == 'stage'){
            return {
                address_account: walletData.stage_v_dwh_address_account,
                aggregate: walletData.stage_v_dwh_address_account_aggregate.aggregate
            }
        }

        if( deploymentStage == 'v1'){
            return {
                address_account: walletData.prod_v_dwh_address_account,
                aggregate: walletData.prod_v_dwh_address_account_aggregate.aggregate
            }
        }

        return null;
    }


    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const getBalanceData = async (limit, offset, public_address) => {
        var balanceData = await getDataAuthorized('https://wealthy-turtle-90.hasura.app/api/rest/' + deploymentStage 
                                                + '/getTokenBalances/'  + limit + '/' + offset + '/' + public_address);
        
        if(balanceData == null ) { 
            return null
        }
        
        if( deploymentStage == 'dbdev'){
            return {
                balance_latest_price: balanceData.dbdev_v_dwh_balance_latest_price,
                aggregate: balanceData.dbdev_v_dwh_balance_latest_price_aggregate.aggregate
            }
        }

        if( deploymentStage == 'stage'){
            return {
                balance_latest_price: balanceData.stage_v_dwh_balance_latest_price,
                aggregate: balanceData.stage_v_dwh_balance_latest_price_aggregate.aggregate
            }
        }

        if( deploymentStage == 'v1'){
            return {
                balance_latest_price: balanceData.prod_v_dwh_balance_latest_price,
                aggregate: balanceData.prod_v_dwh_balance_latest_price_aggregate.aggregate
            }
        }

        return null;
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const getStakingHistoryData_old = async (limit, offset, public_address) => {

                                              
        var url = 'https://wealthy-turtle-90.hasura.app/v1/graphql'; 

        const HASURA_QUERY = `
        query MyQuery($limit: Int = 30, $offset: Int = 0, $public_address: String = "") {
            ` + stageGraphQL  + `_rdx_staking_history(where: {public_address: {_eq: $public_address}, reward: {_gt: 0}}, limit: $limit, offset: $offset, order_by: {timestamp: desc}) {
              amount
              price_usd
              public_address
              reward
              timestamp
              node
              id
            }
            ` + stageGraphQL  + `_rdx_staking_history_aggregate(where: {public_address: {_eq: $public_address}}) {
              aggregate {
                count
              }
            }
          }
        `;

        const variables = {limit: limit, offset: offset, public_address: public_address};
        
        const body = JSON.stringify({
            query: HASURA_QUERY,
            variables: variables
          })
          
        //console.log(url)
        //console.log(body)
        
        var responseData = await fetchAuthorizedBody(url, body)
        //console.log(responseData)
 
        if (responseData != null){
            if( deploymentStage == 'dbdev'){
                return {
                    items: responseData.data.dbdev_rdx_staking_history,
                    aggregate: responseData.data.dbdev_rdx_staking_history_aggregate.aggregate
                }
            }

            if( deploymentStage == 'stage'){
                return {
                    items: responseData.data.stage_rdx_staking_history,
                    aggregate: responseData.data.stage_rdx_staking_history_aggregate.aggregate
                }
            }

            if( deploymentStage == 'v1'){
                return {
                    items: responseData.data.prod_rdx_staking_history,
                    aggregate: responseData.data.prod_rdx_staking_history_aggregate.aggregate
                }
            }
        }

        return null;
    }

        //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const getStakingHistoryData = async (limit, offset, year) => {

                                              
        var url = 'https://wealthy-turtle-90.hasura.app/v1/graphql'; 

        const HASURA_QUERY = `
        query MyQuery($limit: Int = 30, $offset: Int = 0, $year: float8 = "") {
            ` + stageGraphQL  + `_v_rdx_staking_history(where: {year: {_eq: $year}}, limit: $limit, offset: $offset, order_by: [{year: desc},{month: desc},{day: desc}]) {
                account_no
                amount_sum
                day
                month
                price_usd_avg
                reward_sum
                year
            }
            ` + stageGraphQL  + `_v_rdx_staking_history_aggregate(where: {}) {
              aggregate {
                count
              }
            }
          }
        `;

        const variables = {limit: limit, offset: offset, year: year};
        
        const body = JSON.stringify({
            query: HASURA_QUERY,
            variables: variables
          })
          
        //console.log(url)
        //console.log(body)
        
        var responseData = await fetchAuthorizedBody(url, body)
        //console.log(responseData)
 
        if (responseData != null){
            if( deploymentStage == 'dbdev'){
                return {
                    items: responseData.data.dbdev_v_rdx_staking_history,
                    aggregate: responseData.data.dbdev_v_rdx_staking_history_aggregate.aggregate
                }
            }

            if( deploymentStage == 'stage'){
                return {
                    items: responseData.data.stage_v_rdx_staking_history,
                    aggregate: responseData.data.stage_v_rdx_staking_history_aggregate.aggregate
                }
            }

            if( deploymentStage == 'v1'){
                return {
                    items: responseData.data.prod_v_rdx_staking_history,
                    aggregate: responseData.data.prod_v_rdx_staking_history_aggregate.aggregate
                }
            }
        }

        return null;
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const getTransactionData = async (limit, offset, public_address) => {

                                              
        var url = 'https://wealthy-turtle-90.hasura.app/v1/graphql'; 

        var token_filter = '';

        const HASURA_QUERY = `
        query getTransactions($limit: Int = 30, $offset: Int = 0, $public_address: String = "") {
            ` + stageGraphQL  + `_v_dwh_transaction(limit: $limit, offset: $offset, order_by: {tx_timestamp: desc}, where: {public_address: {_eq: $public_address}}) {
                transaction_id
                amount_send_token_id
                amount_send_token_id_short
                amount_send_value
                amount_send_value_usd
                amount_recv_value
                amount_recv_value_usd
                amount_recv_token_id
                amount_recv_token_id_short
                amount_fee_value
                amount_fee_dwh_token_id
                amount_fee_value_usd
                recv_token_icon_url
                recv_token_name
                recv_token_symbol
                send_token_icon_url
                send_token_name
                send_token_symbol
                tx_hash
                tx_hash_short
                tx_timestamp
                tx_failed
                tx_type
                chain_id
                public_address
                public_address_short
                tx_type_no
            }
            ` + stageGraphQL  + `_v_dwh_transaction_aggregate(where: {public_address: {_eq: $public_address}}) {
                aggregate {
                count
                }
            }
        }
        `;

        const variables = {limit: limit, offset: offset, public_address: public_address};
        
        const body = JSON.stringify({
            query: HASURA_QUERY,
            variables: variables
          })
          
        console.log(url)
        //console.log(body)
        
        var responseData = await fetchAuthorizedBody(url, body)
        console.log(responseData)
 
        if (responseData != null){
            if( deploymentStage == 'dbdev'){
                return {
                    transaction: responseData.data.dbdev_v_dwh_transaction,
                    aggregate: responseData.data.dbdev_v_dwh_transaction_aggregate.aggregate
                }
            }

            if( deploymentStage == 'stage'){
                return {
                    transaction: responseData.data.stage_v_dwh_transaction,
                    aggregate: responseData.data.stage_v_dwh_transaction_aggregate.aggregate
                }
            }

            if( deploymentStage == 'v1'){
                return {
                    transaction: responseData.data.prod_v_dwh_transaction,
                    aggregate: responseData.data.prod_v_dwh_transaction_aggregate.aggregate
                }
            }
        }

        return null;
    }
    
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const getManualTransactionData = async (fileName, account_no, account_name) => {

        var url = 'https://wealthy-turtle-90.hasura.app/v1/graphql'; 

        const FILE_UPLOAD_MUTATION = `
        mutation FileUpload($account_name: String = "", $base64str: String = "", $name: String = "", $type: String = "") {
            act_` + stageGraphQL  + `_file_upload(account_name: $account_name, base64str: $base64str, name: $name, type: $type) {
              id
            }
          }
        `;

        const variables = {name: fileName, type: fileType, base64str: base64str};
        
        const body = JSON.stringify({
            query: FILE_UPLOAD_MUTATION,
            variables: variables
          })
          
        //console.log(url)
        //console.log(body)
        
        var response = await fetchAuthorizedBody(url, body)
        //console.log(response)
 
        if (response && response.status == 200){
            return true;
        }

        return false;
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const InsertJob = async (job_no, params) => {
        
        var url = 'https://wealthy-turtle-90.hasura.app/v1/graphql'; 

        const HASURA_QUERY = `
        mutation MyMutation($job_no: String = "", $params: String = "") {
            insert_` + stageGraphQL  + `_scanner_jobs(objects: {job_no: $job_no, params: $params}) {
              affected_rows
            }
          }
          
        `;
          
        // Note: account_no will be automatically filled in by hasura
        const variables = {
            job_no: job_no
          , params: params
        };
        
        const body = JSON.stringify({
            query: HASURA_QUERY,
            variables: variables
            })
            
        //console.log(url)
        //console.log(body)
        
        var responseData = await fetchAuthorizedBody(url, body)
        //console.log(responseData)
        return responseData;

    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const OrderDTokenReport = async (email_address, public_address_list, retries=0) => {
        
        var url = 'https://wealthy-turtle-90.hasura.app/v1/graphql'; 

        const HASURA_QUERY_ORI = `
        mutation MyMutation($public_address_list: String = "", $email_address: String = "") {
            insert_` + stageGraphQL  + `_dwh_dtoken_report(objects: {public_address_list: $public_address_list, email_address: $email_address}) {
              affected_rows
            }
          }
        `;

        const HASURA_QUERY = `
        mutation MyMutation($email_address: String = "test@test.de", $public_address_list: String = "test,test") {
            insert_prod_dwh_dtoken_report(objects: {email_address: $email_address, public_address_list: $public_address_list}) {
              affected_rows
            }
          }
        `;
          
        // Note: account_no will be automatically filled in by hasura
        const variables = {
            email_address: email_address
          , public_address_list: public_address_list
        };
        
        const body = JSON.stringify({
            query: HASURA_QUERY,
            variables: variables
            })
            
        console.log(url)
        console.log(body)
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: body
        };
        
        var retData = null;
        await fetch(url, requestOptions)
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                retData = data;
            })

        
        //TODO: retry in case it fails
        console.log(retData)
        if ('errors' in retData) {

            if (retries > 3)
                return null; 

            return OrderDTokenReport(email_address, public_address_list, retries+1);
        }

        return retData;
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    
      const insertTransactionManipulation = async (dwh_transaction_id, amount_send_value_usd, amount_recv_value_usd, amount_fee_value_usd, account_name, tx_type_no) => {
                                              
        var url = 'https://wealthy-turtle-90.hasura.app/v1/graphql'; 

        const HASURA_QUERY = `
        mutation insert_transaction_manipulation($dwh_transaction_id: String = "", $amount_send_value_usd: numeric = "", $amount_recv_value_usd: numeric = "", $amount_fee_value_usd: numeric = "", $account_name: String = "", $tx_type_no: String = "") {
            insert_` + stageGraphQL  + `_dwh_transaction_manipulation(objects: {account_name: $account_name, amount_recv_value_usd: $amount_recv_value_usd, amount_send_value_usd: $amount_send_value_usd, amount_fee_value_usd: $amount_fee_value_usd, dwh_transaction_id: $dwh_transaction_id, tx_type_no: $tx_type_no}) {
              affected_rows
            }
          }         
        `;

        const variables = {
              dwh_transaction_id: dwh_transaction_id
            , account_name: account_name
            , tx_type_no: tx_type_no 
            , amount_send_value_usd: amount_send_value_usd
            , amount_recv_value_usd: amount_recv_value_usd
            , amount_fee_value_usd: amount_fee_value_usd
        };
        
        const body = JSON.stringify({
            query: HASURA_QUERY,
            variables: variables
          })
          
        //console.log(url)
        //console.log(body)
        
        var responseData = await fetchAuthorizedBody(url, body)
        //console.log(responseData)
        if( responseData != null && responseData.data.insert_dbdev_dwh_transaction_manipulation.affected_rows == 1)
        {
            return true;
        } 
        return false;
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const getBalanceHistoryUSD = async () => {
        var data = await getDataAuthorized('https://wealthy-turtle-90.hasura.app/api/rest/' + deploymentStage +'/getBalanceHistoryUSD');

        if(data == null ) { 
            return null
        }

        if( deploymentStage == 'dbdev'){
            return {
                balance_history_usd: data.dbdev_v_dwh_balance_history_usd
            }
        }

        if( deploymentStage == 'stage'){
            return {
                balance_history_usd: data.stage_v_dwh_balance_history_usd
            }
        }

        if( deploymentStage == 'v1'){
            return {
                balance_history_usd: data.prod_v_dwh_balance_history_usd
            }
        }

        return null
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const getTaxTradesData = async (limit, offset, year=null) => {

        var url = 'https://wealthy-turtle-90.hasura.app/v1/graphql'; 

        const HASURA_QUERY = `
        query getTaxTrades($limit: Int = 30, $offset: Int = 0, $sell_datetime_lte: Int = 0, $sell_datetime_gte: Int = 0) {
            ` + stageGraphQL  + `_v_dwh_tax_trades(limit: $limit, offset: $offset, order_by: {buy_datetime: asc, sell_datetime: asc}, where: {sell_datetime: {_lte: $sell_datetime_lte, _gte: $sell_datetime_gte}}) {
              account_no
              account_name
              amount
              buy_datetime
              buy_tx_hash
              buy_value_usd
              fees_value_usd
              sell_datetime
              sell_tx_hash
              sell_value_usd
              token_id
              token_name
              token_symbol
              token_identifier
              token_icon_url
              token_identifier_short
              profit_or_loss
            }
            ` + stageGraphQL  + `_v_dwh_tax_trades_aggregate {
              aggregate {
                count
                sum {
                  profit_or_loss
                }
              }
            }
          }
        `;
        
        var datetime_year_lte = 0;
        switch (year) {
            case '2027': datetime_year_lte = 1830293999; break;
            case '2026': datetime_year_lte = 1798757999; break;
            case '2025': datetime_year_lte = 1767221999; break;
            case '2024': datetime_year_lte = 1735685999; break;
            case '2023': datetime_year_lte = 1704063599; break;
            case '2022': datetime_year_lte = 1672527599; break;
        }
        var datetime_year_gte = datetime_year_lte - 31536000; // seconds per year

        if (year == null){
            datetime_year_lte = 1830293999; // some high number
            datetime_year_gte = 0;
        }

        const variables = {limit: limit, offset: offset, sell_datetime_lte: datetime_year_lte, sell_datetime_gte: datetime_year_gte};
        
        const body = JSON.stringify({
            query: HASURA_QUERY,
            variables: variables
        })
        
        console.log(url)
        //console.log(body)
        
        var responseData = await fetchAuthorizedBody(url, body)
        console.log(responseData)

        if(responseData == null ) { 
            return null
        }

        if( deploymentStage == 'dbdev'){
            return {
                taxTrades: responseData.data.dbdev_v_dwh_tax_trades,
                aggregate: responseData.data.dbdev_v_dwh_tax_trades_aggregate.aggregate
            }
        }

        if( deploymentStage == 'stage'){
            return {
                taxTrades: responseData.data.stage_v_dwh_tax_trades,
                aggregate: responseData.data.stage_v_dwh_tax_trades_aggregate.aggregate
            }
        }

        if( deploymentStage == 'v1'){
            return {
                taxTrades: responseData.data.prod_v_dwh_tax_trades,
                aggregate: responseData.data.prod_v_dwh_tax_trades_aggregate.aggregate
            }
        }

        return null
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const getTaxFifosData = async () => {

        var url = 'https://wealthy-turtle-90.hasura.app/v1/graphql'; 

        const HASURA_QUERY = `
        query getTaxFifos {
            ` + stageGraphQL  + `_v_dwh_tax_fifos(where: {}, order_by: {datetime: asc}) {
                amount
                datetime
                token_icon_url
                token_id
                token_identifier
                token_identifier_short
                token_name
                token_symbol
                tx_hash
                value_usd
            }
        }
        `;
  
        const variables = {};
        
        const body = JSON.stringify({
            query: HASURA_QUERY,
            variables: variables
        })
        
        //console.log(body)
        
        var responseData = await fetchAuthorizedBody(url, body)
        //console.log(responseData)

        if(responseData == null ) { 
            return null
        }

        if( deploymentStage == 'dbdev'){
            return {
                items: responseData.data.dbdev_v_dwh_tax_fifos
            }
        }

        if( deploymentStage == 'stage'){
            return {
                items: responseData.data.stage_v_dwh_tax_fifos
            }
        }

        if( deploymentStage == 'v1'){
            return {
                items: responseData.data.prod_v_dwh_tax_fifos
            }
        }

        return null
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const getTokenLoanHistory = async () => {

        var url = 'https://wealthy-turtle-90.hasura.app/v1/graphql'; 

        const HASURA_QUERY = `
        query getTokenLoanHistory {
            ` + stageGraphQL  + `_dwh_token_loan_history(order_by: {timestamp: asc}) {
                id
                chain_id
                dwh_token_id
                timestamp
                from_public_address
                to_public_address
                amount
                amount_usd
                is_interest
            }
            ` + stageGraphQL  + `_dwh_token_loan_history_aggregate {
                aggregate {
                  count
                }
              }
        }
        `;
  
        const variables = {};
        
        const body = JSON.stringify({
            query: HASURA_QUERY,
            variables: variables
        })
        
        console.log(url)
        //console.log(body)
        
        var responseData = await fetchAuthorizedBody(url, body)
        console.log(responseData)

        if(responseData == null ) { 
            return null
        }

        if( deploymentStage == 'dbdev'){
            return {
                items: responseData.data.dbdev_dwh_token_loan_history,
                aggregate: responseData.data.dbdev_dwh_token_loan_history_aggregate.aggregate
            }
        }

        if( deploymentStage == 'stage'){
            return {
                items: responseData.data.stage_dwh_token_loan_history,
                aggregate: responseData.data.stage_dwh_token_loan_history_aggregate.aggregate
            }
        }

        if( deploymentStage == 'v1'){
            return {
                items: responseData.data.prod_dwh_token_loan_history,
                aggregate: responseData.data.prod_dwh_token_loan_history_aggregate.aggregate
            }
        }

        return null
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------


    return { getWalletData, getBalanceData, getTransactionData, getStakingHistoryData, getTaxTradesData, 
        getTaxFifosData, getBalanceHistoryUSD, addWallet, removeWallet, uploadCEXCSV,
        getUploads, insertTransactionManipulation, InsertJob, OrderDTokenReport, getTokenLoanHistory }
};