import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function useRupineAPI() {
    
    const { isAuthenticated, isLoading, getIdTokenClaims, logout  } = useAuth0();
    const [data, setData] = useState(false);
  
    const sleep = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const fetchAuthorized = async (url) => {

        if(isLoading)
        {
            // Dirty hack. Don't know how to wait for loading done otherwise
            await sleep(3000);
        }
        else if (!isAuthenticated)
        {
            logout({ returnTo: "https://www.gem-hive.com/#/dashboard/overview"})
            return null;
        }
        
        const claims = await getIdTokenClaims();
        const id_token = claims.__raw;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + id_token);
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
        };
        
        var retData = null;
        await fetch(url, requestOptions)
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                retData = data;
            })
    
        return retData;
    }
    
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const fetchAuthorizedBody = async (url, myBody) => {
        
        if(isLoading)
        {
            // Dirty hack. Don't know how to wait for loading done otherwise
            await sleep(3000);
        }
        else if (!isAuthenticated)
        {
            logout({ returnTo: "https://www.gem-hive.com/#/dashboard/overview"})
            return null;
        }

        const claims = await getIdTokenClaims();
        const id_token = claims.__raw;
        //console.log(id_token)
        //console.log(url)

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + id_token);
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: myBody
        };
        
        var retData = null;
        await fetch(url, requestOptions)
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                retData = data;
            })

        
        return retData;
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    const getDataAuthorized = async (url) => {

        if(isLoading)
        {
            // Dirty hack. Don't know how to wait for loading done otherwise
            await sleep(3000);
        }
        else if (!isAuthenticated)
        {
            logout({ returnTo: "https://www.gem-hive.com/#/dashboard/overview"})
            return null;
        }

        try
        {
            const claims = await getIdTokenClaims();
            const id_token = claims.__raw;
            //console.log(id_token)
            //console.log(url)
        
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", 'Bearer ' + id_token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                };  

            var retData = null;
            
            await fetch(url, requestOptions)
            .then((response) => {
                if(response.status === 200){ 
                    return response.json();
                }
                else
                {
                    console.log('RupineAPI - GET - Request failed ' + response.status)
                }
            })
            .then((data) => {
                retData = data;
            })

            return retData
        }
        catch{
        }

        //console.log('return null')
        return null
    }

    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------

    return { getDataAuthorized, fetchAuthorized, fetchAuthorizedBody }
};