// =========================================================
// * Gem Hive - Crypto Portfolio Tracker
// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. Please contact us to request a removal.

import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";
import {Auth0Provider, AppState } from "@auth0/auth0-react"
import PiwikPro from '@piwikpro/react-piwik-pro';

// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

// Homepage
import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";

// Web3 (Metamask)
import { Web3 } from 'web3'
import { Web3ReactProvider } from '@web3-react/core'
import { MetaMaskProvider } from './components/metamask'

const onRedirectCallback = (appState) => {
  // If using a Hash Router, you need to use window.history.replaceState to
  // remove the `code` and `state` query parameters from the callback url.
  window.history.replaceState({}, document.title, window.location.pathname);
  //history.replace((appState && appState.returnTo) || window.location.pathname);
};

function getLibrary(provider, connector) {
  return new Web3(provider)
}

PiwikPro.initialize('eb533cd0-c33f-4aea-bb6a-f49f9a37fd0d', 'https://rupinelabs.containers.piwik.pro');

ReactDOM.render(
  <Auth0Provider
    domain="dev-rxynk4-z.eu.auth0.com"
    clientId="hbojXngKCahI9uvLRu7oQh4IfovShuQt"
    //redirectUri="https://127.0.0.1:3001/#/dashboard/overview">
    redirectUri="https://www.gem-hive.com/#/dashboard/overview">
      <Web3ReactProvider getLibrary={getLibrary}>
          {/*<MetaMaskProvider>*/}
            <HashRouter>
              <ScrollToTop />
              <HomePage />
            </HashRouter>
          {/*</MetaMaskProvider>*/}
      </Web3ReactProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

