
export const Routes = {
    // pages
    Presentation: { path: "/" },
    DashboardOverview: { path: "/dashboard/overview" },
    Balances: { path: "/balances" },
    UploadCenter: { path: "/uploadcenter" },
    Transactions: { path: "/transactions" },
    Staking: { path: "/staking" },
    Lending: { path: "/lending" },
    TaxTrades: { path: "/taxtrades" },
    TaxFifos: { path: "/taxfifos" },
    TaxReport: { path: "/taxreport" },
    Impressum: { path: "/impressum" },
    Profile: { path: "/profile" },
    PrivacyPolicy: { path: "/privacypolicy" },
    // Left-overs of themesberg Template 
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },
};