import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Nav, Card, Button, Table, Dropdown, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";

export const TaxFifoTable = (props) => {

  const TableRow = (props) => {
    return (
      <tr>
        <td>
          <span className="fw-normal">
            <p class="mb-0 pb-0">{parseFloat(props.amount).toFixed(2)} {props.token_symbol.toUpperCase()}</p>
            <p class="mb-0 pb-0"><img src={props.token_icon_url} height={25} className="mb-1" /> {props.token_name}</p>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <p class="mb-0 pb-0">$ {parseFloat(props.value_usd).toFixed(2)}</p>
            <p class="mb-0 pb-0">Price: ${parseFloat(props.value_usd / props.amount).toFixed(8)}</p>
            <p class="fs-6 mb-0 pb-0">{new Date(props.datetime * 1000).toLocaleString()}
              <a href={"https://explorer.radixdlt.com/#/transactions/" + props.tx_hash} target="_blank">
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2"/>
              </a>
            </p>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <p class="fs-6 mb-0 pb-0">{parseInt((new Date() - new Date(props.datetime * 1000)) / 86400000)} Days</p>
          </span>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Token</th>
              <th className="border-bottom">Open Position</th>
              <th className="border-bottom">HODL Duration</th>
            </tr>
          </thead>
          <tbody>
            {props.countPageItems > 0 ? props.items.map(t => <TableRow key={t.id}{...t}/>) : <tr/> }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
