import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Nav, Card, Button, Table, Dropdown, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";

export const TaxStakingTable = (props) => {

  const TableRow = (props) => {
    return (
        <>
        { props.amount_sum > 0 ? 
        <tr>
           <td>
            <span className="fw-normal">
            <p class="fs-6 mb-0 pb-0">{props.day}-{props.month}-{props.year}</p>
            </span>
          </td>
          <td>
              <span > <p class="fs-6 mb-0 pb-0">XRD</p></span>
          </td>
          <td>
            {
              props.reward_sum > 0 ? 
              <span >
                <p class="fs-6 mb-0 pb-0">{(parseFloat(props.reward_sum) / Math.pow(10, 18) ).toLocaleString('en-US')} </p>
              </span>
              : ''
            }
          </td>
          <td>
            {
              props.reward_sum > 0 ? 
              <span >
                <p class="fs-6 mb-0 pb-0">${(parseFloat(props.reward_sum) / Math.pow(10, 18) * parseFloat(props.price_usd_avg)).toFixed(2).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</p>
              </span>
              : ''
            }
          </td>
      </tr>
      : ''
    }
    </>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Date</th>
              <th className="border-bottom">Coin</th>
              <th className="border-bottom">Profit</th>
              <th className="border-bottom">Profit (USD)</th>
            </tr>
          </thead>
          <tbody>
            {props.countPageItems > 0 ? props.items.map(t => <TableRow key={t.id}{...t}/>) : <tr/> }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
