import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Modal, Spinner } from '@themesberg/react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";

import { BalancesTable } from "./tables/BalancesTables";
import { WalletTable } from "./tables/WalletTable";
import { NewAddress } from "./components/NewAddress";
import { WalletEdit } from "./components/WalletEdit";
import useRupineAPI from "../data/RupineAPI";
import useHasuraAPI from "../data/HasuraAPI";
import { chain, isArguments } from "lodash";

export default () => {
  
  // wallet edit dialog
  const [showAddModalDlg, setShowAddModalDlg] = useState(false);
  const [showDefault, setShowDefault] = useState(false);
  const [isWalletEdit, setIsWalletEdit] = useState(false);
  const [editFields, setEditFields] = useState({});

  // loading component
  const { getWalletData, getBalanceData, addWallet, removeWallet } = useHasuraAPI();
  const { isAuthenticated, getIdTokenClaims  } = useAuth0();

  // States for Wallet Table
  const [wallets, setWallets] = useState([]);
  const [totalWallets, setTotalWallets] = useState(0);
  const [walletPages, setWalletPages] = useState([1]);
  const [pageWallets, setPageWallets] = useState(0);
  const [selectedWallet, setSelectedWallet] = useState(1);

  // States for Balance Table
  const [balances, setBalances] = useState([]);
  const [totalBalances, setTotalBalances] = useState(0);
  const [balancePages, setBalancePages] = useState([1]);
  const [pageBalance, setPageBalance] = useState(0);
  const [balancePageActive, setBalancePageActive] = useState(1);

  // Loading state
  const [loading, setLoading] = useState(true);


  useEffect(() => {

    async function fetchData() {

      // Set wallet data
      const limitWallet = 30;
      var walletData = await getWalletData(limitWallet,0);
      
      if(walletData == null ){
        setLoading(false);
        return;
      }

      setWallets(walletData.address_account)
      setPageWallets(walletData.address_account.length);
      setTotalWallets(walletData.aggregate.count);
      var _pageCount = Math.ceil(walletData.aggregate.count / limitWallet);
      let _pages = [];
      for (let i = 1; i <= _pageCount; i++) {
          _pages = _pages.concat(i);
      }
      setWalletPages(_pages)

      if (walletData.address_account.length > 0)
      {
        setSelectedWallet(walletData.address_account[0])
        await LoadBalanceData(walletData.address_account[0].public_address, 1);
      }
      setLoading(false)
    }

    fetchData();
    return () => {};
  }, []);

  const handleAddressAdded = async () => { 
     
    setLoading(true)
    //console.log('reload wallet data')
    await LoadWalletData();
    //console.log('reload done')
    if (wallets.length > 0)
    {
      setLoading(true)
      LoadBalanceData(wallets[0].public_address, 1);
    }
    setLoading(false)
  };

  const submitNewAddress = async (chain_id, public_address) => {

    setLoading(true);
    var addSuccess = await addWallet(chain_id, public_address, 'no comment', 'N', 'Wallet')
    
    //console.log('added wallet')
    //console.log(addSuccess)
    if(addSuccess){
        setShowAddModalDlg(true);
        handleAddressAdded();
    }
    setLoading(false);
  }


  const LoadWalletData = async() => {
    // Set wallet data
    var limitWallet = 30;
    var walletData = await getWalletData(limitWallet, 0);
    
    if(walletData == null ){
      setLoading(false);
      return;
    }
    
    setWallets(walletData.address_account)
    setPageWallets(walletData.address_account.length);
    setTotalWallets(walletData.aggregate.count);
    var _pageCount = Math.ceil(walletData.aggregate.count / limitWallet);
    let _pages = [];
    for (let i = 1; i <= _pageCount; i++) {
        _pages = _pages.concat(i);
    }
    setWalletPages(_pages)
    setLoading(false)
  }

  const LoadBalanceData = async(public_address, page) => {

    // Set balance data
    setBalancePageActive(page)
    var limitBalance = 30;
    var offsetBalance = (page-1) * limitBalance;
    var balanceData = await getBalanceData(limitBalance, offsetBalance, public_address);
    
    if(balanceData == null ){
      setLoading(false);
      return;
    }

    
    setBalances(balanceData.balance_latest_price)
    setPageBalance(balanceData.balance_latest_price.length);
    setTotalBalances(balanceData.aggregate.count);
    var _pageCount_b = Math.ceil(balanceData.aggregate.count / limitBalance);
    let _pages_b = [];
    for (let i = 1; i <= _pageCount_b; i++) {
      _pages_b = _pages_b.concat(i);
    }
    setBalancePages(_pages_b)
    setLoading(false)
  }

  const selectWalletHandler = async(wallet) => {
    setLoading(true)
    setSelectedWallet(wallet)
    LoadBalanceData(wallet.public_address, 1)
  }
  
  const balancePageClick = async(page) => {
    setLoading(true);
    LoadBalanceData(selectedWallet.public_address, page);
  }

  const handleWalletEdit = async (wallet, isEdit) => { 

    // set dialog editable state
    setIsWalletEdit(isEdit)

    // set current values for update
    var editFieldsTemp = editFields; 
    editFieldsTemp['icon_url'] = wallet.icon_url;
    editFieldsTemp['chain_id'] = wallet.chain_id;
    editFieldsTemp['public_address'] = wallet.public_address;
    editFieldsTemp['public_address_short'] = wallet.public_address_short;
    editFieldsTemp['public_address_name'] = wallet.public_address_name;
    editFieldsTemp['is_business'] = wallet.is_business;
    editFieldsTemp['comment'] = wallet.comment;
    setEditFields(editFieldsTemp);

    // display dialog
    setShowDefault(true)
  };

  const handleAddModalDlgClose = () => {
    setShowAddModalDlg(false);
  };

  const handleDlgClose = () => {
    setShowDefault(false);
  };

  const handleDlgSave = async () => {
    setShowDefault(false);
    setLoading(true)
    UpdateWallet();
  };

  const handleInputChange = (event) => {
    //setPuba({puba: event.target.value});
    var editFieldsTemp = editFields
    editFieldsTemp[event.target.name] = event.target.value 
    setEditFields(editFieldsTemp)
}

  const RemoveWallet = async (removeId) => 
  { 
    setLoading(true)

    var removeSucess = removeWallet(removeId);
    if(removeSucess){
      var newArr = wallets.filter(item => item.id != removeId)
      setWallets(newArr)
      setPageWallets(newArr.length)
      setTotalWallets(newArr.length)

      if(newArr.length > 0)
      {
        LoadBalanceData(newArr[0].public_address, 1)
      }
    }
  }

  const UpdateWallet = async () => {

    var addSuccess = await addWallet( editFields['chain_id'], editFields['public_address'], editFields['comment']
                                    , editFields['is_business'], editFields['public_address_name'])

                                
    if(addSuccess)
    {
        // update wallet without reloading
        var walletsTemp = wallets;
        walletsTemp.forEach((el)=>{
          if (el.public_address == editFields['public_address'] && 
                el.chain_id == editFields['chain_id'])
            {
                  el.comment = editFields['comment'];
                  el.is_business = editFields['is_business'];
                  el.public_address_name = editFields['public_address_name'];
            }
        })
        setWallets(walletsTemp);
        setLoading(false)
    }

    setLoading(false)
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Wallets & Balances</h4>
          {
            isAuthenticated ?
              totalWallets >= 100 
              ? <Row><p>Gem hive is currently limited to 100 wallets - please DM us @ https://twitter.com/RUPINE_Labs for more</p></Row>
              :
                <div>
                  <NewAddress addHandler={submitNewAddress}/>
                </div>
            : <Row><p>Login to track your wallet</p></Row>
          }
        </div>
      </div>

      { 
        loading ? 
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        :
        <div className="d-flex flex-row bd-highlight mb-3">
          <div class="mx-1">

              <WalletTable 
                  wallets={wallets}
                  selectedWallet={selectedWallet} 
                  totalWallets={totalWallets} 
                  pageWallets={pageWallets}
                  pages={walletPages} 
                  activePage={1}
                  ClickHandler={selectWalletHandler}
                  RemoveHandler={RemoveWallet}
                  EditHandler={handleWalletEdit}/>
            </div>
            <div class="mx-1">
                <BalancesTable 
                    balances={balances} 
                    totalBalances={totalBalances}
                    pageBalance={pageBalance}
                    pages={balancePages} 
                    activePage={balancePageActive}
                    ClickHandler={balancePageClick}/>
            </div>
        </div>
      }

      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleDlgClose}>
        <Modal.Header>
          {
            isWalletEdit ? <Modal.Title className="h6">Wallet Edit</Modal.Title>
                         : <Modal.Title className="h6">Wallet Details</Modal.Title>
          }
          <Button variant="close" aria-label="Close" onClick={handleDlgClose} />
        </Modal.Header>
        <Modal.Body>
          <WalletEdit 
            isWalletEdit={isWalletEdit} 
            icon_url ={editFields.icon_url}
            comment={editFields.comment}
            is_business={editFields.is_business}
            public_address={editFields.public_address}
            public_address_short={editFields.public_address_short}
            public_address_name={editFields.public_address_name}
            handleInputChange={handleInputChange}/>
        </Modal.Body>
        <Modal.Footer>
          {
            isWalletEdit ? <Button variant="secondary" onClick={handleDlgSave}>Save</Button>
                         : <Button variant="secondary" onClick={handleDlgClose}>Ok</Button>
          }
        </Modal.Footer>
      </Modal>
      <Modal as={Modal.Dialog} centered show={showAddModalDlg} onHide={handleAddModalDlgClose}>
        <Modal.Header>
          <Modal.Title className="h6">Address added</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleAddModalDlgClose} />
        </Modal.Header>
        <Modal.Body>
          <p>Querying data might take several minutes</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAddModalDlgClose}>
            Ok
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
