
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Dropdown, Form, Image, Navbar, Button, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';
import { useAuth0, User } from "@auth0/auth0-react"

import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/team/profile-picture-3.jpg";

// Metamask imports
import useMetaMask from "./metamask";

// Auth0 imports
import MyAuth0Login from "./MyAuth0Login";
import MyAuth0Logout from "./MyAuth0Logout";

export default (props) => {

  const { isAuthenticated, user, picture, logout } = useAuth0();
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);
  //const { connect, disconnect, isActive, account, shouldDisable } = useMetaMask()
  const [state, setState] = useState({ network:'XRD'});
  
  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map(n => ({ ...n, read: true })));
    }, 300);
  };

  const handleInputChange = (event) => {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    setState({[name]: value});
    console.log(state)
  }  

  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image src={image} className="user-avatar lg-avatar rounded-circle" />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex flex-row-reverse justify-content-between w-100">
          <Nav>
            {/*
              <select name="network" value={state.network} onChange={handleInputChange}>
                  <option value="ETH">Ethereum (ETH)</option>
                  <option value="XRD">Radix (XRD)</option>
                  <option value="DFI">DeFiChain (DFI)</option>
              </select>
            */}
            {
             /*
             state.network == 'ETH' ?
              isActive ? 
                  <Button variant="danger" onClick={disconnect}>
                    Disconnect MetaMask<img src="images/noun_waving_3666509.svg" width="20" height="20" />
                  </Button>
                : 
                  <Button variant="secondary" onClick={connect} disabled={shouldDisable}>
                    <img src="images/metamask.svg" alt="" width="20" height="20" /> Connect to MetaMask
                  </Button>
              : state.network == 'XRD' ?
                <label>Connect to Zeus</label>
              : state.network == 'DFI' ?
                <label>Connect to DFI Wallet</label>
              :
                <label>not wallet support</label>
                */
            }


            {
              isAuthenticated ?
                <Dropdown as={Nav.Item}>
                  <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                    <div className="media d-flex align-items-center">
                      <Image src={user.picture} className="user-avatar md-avatar rounded-circle" />
                      <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                        <span className="mb-0 font-small fw-bold">{user.name}</span>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  {/*
                    <Dropdown.Item className="fw-bold">
                      <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                    </Dropdown.Item>
                    <Dropdown.Item className="fw-bold">
                      <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                    </Dropdown.Item>
                    <Dropdown.Item className="fw-bold">
                      <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Messages
                    </Dropdown.Item>
                    <Dropdown.Item className="fw-bold">
                      <FontAwesomeIcon icon={faUserShield} className="me-2" /> Support
                    </Dropdown.Item>

                    <Dropdown.Divider />
                    */}

                    <Dropdown.Item className="fw-bold" onClick={() => { logout({ returnTo: window.location.origin }); }}>
                      <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              :
              <MyAuth0Login/>
            }
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
