import React, { useEffect, useState } from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faCopy, faAngleUp, faEdit, faEllipsisH, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Nav, Card, Button, Table, Dropdown, Pagination, ButtonGroup, Modal } from '@themesberg/react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";
import { propTypes } from "@themesberg/react-bootstrap/lib/esm/Image";
import { EditTransaction } from "../components/EditTransaction";

export const LendingHistoryTable = (table_props) => {

    const TableRow = (props) => {
      return (
        <tr>
          <td>
            <span className="fw-normal">
              <p class="fs-6 mb-0 pb-0">
                {new Date(props.timestamp * 1000).toLocaleString(
                  "en-US"
                )}
              </p>
            </span>
          </td>
          <td>
            <span className="fw-normal">
              <p class="fs-6 mb-0 pb-0">{props.is_interest == 'Y' ? 'Interest' 
                  : props.from_public_address == '0x0000000000000000000000000000000000000000' ?  'Loan' : 'Payback'}</p>
            </span>
          </td>
          <td>
            <span className="fw-normal">
              <p class="fs-6 mb-0 pb-0">Ethereum (ETH)</p>
              {
              //<p class="fs-6 mb-0 pb-0">{props.dwh_token_id}</p>
              }
            </span>
          </td>
          <td>
            {
              <span >
                <p class="fs-6 mb-0 pb-0">{(parseFloat((props.amount / Math.pow(10, 18) ))).toLocaleString('en-US')}</p>
              </span>
            }
          </td>
          <td>
            {
              <span >
                <p class="fs-6 mb-0 pb-0">${(parseFloat(props.amount_usd / (props.amount / Math.pow(10, 18) ))).toFixed(8).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</p>
              </span>
            }
          </td>
          <td>
            {
              <span >
                <p class="fs-6 mb-0 pb-0">${(parseFloat(props.amount_usd)).toFixed(2).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</p>
              </span>
            }
          </td>
        </tr>
      );
    };
  
    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Date</th>
                <th className="border-bottom">Type</th>
                <th className="border-bottom">Asset</th>
                <th className="border-bottom">Amount</th>
                <th className="border-bottom">Price (USD)</th>
                <th className="border-bottom">Value (USD)</th>
              </tr>
            </thead>
            <tbody>
              {table_props.countPageItems > 0 ? table_props.items.map((t,i) => <TableRow key={i} {...t} />) : <tr/> }
            </tbody>
          </Table>
  
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              { /*table_props.countTotalItems < 365 ? <p></p>
                  : <Pagination className="mb-2 mb-lg-0">
                  {
                    table_props.pages.map(page => (
                      page == table_props.nrActivePage 
                      ? <Pagination.Item active>{page}</Pagination.Item>
                      : <Pagination.Item onClick={() => {table_props.pageClickHandler(page)}} >{page}</Pagination.Item>
                    ))
                  }
                </Pagination>
              */}
            </Nav>
            <small className="fw-bold">
              Showing <b>{table_props.countPageItems}</b> out of <b>{table_props.countTotalItems}</b> entries
            </small>
          </Card.Footer>
        </Card.Body>

      </Card>
    );
  };