import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFilter, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Spinner } from '@themesberg/react-bootstrap';

import { LendingHistoryTable } from "./tables/LendingHistoryTable";
import useRupineAPI from "../data/RupineAPI";
import useHasuraAPI from "../data/HasuraAPI";

export default () => {

  // loading component
  const { getTokenLoanHistory } = useHasuraAPI();

  // Lending Historys
  const [items, setItems] = useState([]);
  const [countTotalItems, setCountTotalItems] = useState(0);
  const [countPageItems, setCountPageItems] = useState([0]);
  const [pages, setPages] = useState(0);
  const [nrActivePage, setNrActivePage] = useState(1);

  // Loading state
  const [loadingTx, setLoadingTx] = useState(true);

  useEffect(() => {

    async function fetchData() {

      var p = 1;
      var limit = 365;
      var offset = (p-1) * limit;
      var data = await getTokenLoanHistory();

      if (data == null)
      {
        setLoadingTx(false)
        return;
      }

      setItems(data.items);
      setCountPageItems(data.items.length);
      setCountTotalItems(data.aggregate.count);
      var _pageCount = Math.ceil(data.aggregate.count / limit);
      let _pages = [];
      let start_index = 1;
      let end_index = _pageCount;
      if (p > 2){
        start_index = p - 2;
      } 
      if (_pageCount > (p+2)){
        end_index = p + 2;
      }
      if(end_index - start_index < 4){
        end_index = start_index + 4;
      }

      for (let i = start_index; i <= end_index; i++) {
          _pages = _pages.concat(i);
      }
      setPages(_pages)
      setLoadingTx(false)
    }

    fetchData();
    return () => {};
  }, []);


  const pageClickHandler = async(page) => {
    setLoadingTx(true);
  }

  const onSelectHandler = async(eventKey, event) => {
    //console.log('onSelectHandler')
    //console.log(eventKey)
    setLoadingTx(true);
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Lending</h4>
          <p>Currently only availale for DeFiChain</p>
        </div>
      </div>

      <div>
        { loadingTx ? 
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
          : <p></p>
          }
      </div>

      { loadingTx ? <p></p>
      :
          <div>
            <LendingHistoryTable 
              items={items} 
              countTotalItems={countTotalItems}
              countPageItems={countPageItems}
              pages={pages} 
              nrActivePage={nrActivePage}
              pageClickHandler={pageClickHandler}/>
          </div>
      }
    </>
  );
};
