import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFilter, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Spinner } from '@themesberg/react-bootstrap';

import { TaxFifoTable } from "../pages/tables/TaxFifoTable";
import useHasuraAPI from "../data/HasuraAPI";

export default () => {

  // loading component
  const { getTaxFifosData } = useHasuraAPI();

  // States for FIFO Table
  const [fifoItems, setFifoItems] = useState([]);
  const [countFifoItems, setCountFifoItems] = useState(0);
  const [uniqueTokenIds, setUniqueTokenIds] = useState([]);

  // Loading state
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {

    async function fetchData() {

      setLoadingData(true);
      await LoadTaxFifosData();
      setLoadingData(false);
    }

    fetchData();
    return () => {};
  }, []);

  const LoadTaxFifosData = async () => 
  {
    var data = await getTaxFifosData();
    console.log(data);
    setFifoItems(data.items)

    let distinct_token_ids = [];
    data.items.forEach((el)=>{
      if(!distinct_token_ids.includes(el.token_id))
      {
        distinct_token_ids.push(el.token_id);
      }
    });

    setUniqueTokenIds(distinct_token_ids);
    setCountFifoItems(distinct_token_ids.length)
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Positions</h4>
        </div>
      </div>

      { loadingData ? 
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        :
          countFifoItems == null ? <p></p>
          :
          <div>
            {/*
            <Dropdown as={ButtonGroup} onSelect={(eventKey, event) => onSelectHandler(eventKey, event)} >
                  <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                    <span className="icon icon-sm icon-gray">
                      <FontAwesomeIcon icon={faFilter} /> Token filter
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-md dropdown-menu-right">
                  {uniqueTokenIds.map((i) => <Dropdown.Item className="fw-bold text-dark" eventKey={i}>{i}</Dropdown.Item>)}
                  </Dropdown.Menu>
            </Dropdown>
            */}

 
            <TaxFifoTable 
              items={fifoItems}
              countPageItems={countFifoItems} />
            </div>
      }
    </>
  );
};
