import React, { useEffect, useState } from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faCopy, faAngleUp, faEdit, faEllipsisH, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Nav, Card, Button, Table, Dropdown, Pagination, ButtonGroup, Modal } from '@themesberg/react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";
import { propTypes } from "@themesberg/react-bootstrap/lib/esm/Image";
import { EditTransaction } from "../components/EditTransaction";

export const TransactionsTable = (table_props) => {

  // edit dialog
  const [showAddModalDlg, setShowAddModalDlg] = useState(false);
  const [showDefault, setShowDefault] = useState(false);
  const [editFields, setEditFields] = useState({});
  const [txToEdit, setTxToEdit] = useState({});

  const handleTransactionEdit = async (transaction) => { 

    //console.log(transaction)
    setTxToEdit(transaction)

    // set current values for update
    var editFieldsTemp = editFields; 
    editFieldsTemp['amount_recv_value_usd'] = transaction.amount_recv_value_usd;
    editFieldsTemp['amount_send_value_usd'] = transaction.amount_send_value_usd;
    editFieldsTemp['amount_fee_value_usd'] = transaction.amount_fee_value_usd;
    editFieldsTemp['category'] = transaction.tx_type_no;
    //console.log(editFieldsTemp)

    // display dialog
    setShowDefault(true)
  };

  const handleDlgClose = () => {
    setShowDefault(false);
  };

  const handleDlgSave = (table_props, txToEdit, editFields) => {
    //console.log('handleDlgSave')
    setShowDefault(false);
    table_props.saveTxEditHandler(txToEdit, editFields); 
  };

  const handleInputChange = (event) => {
    //setPuba({puba: event.target.value});
    //console.log('handleInputChange')
    var editFieldsTemp = editFields
    editFieldsTemp[event.target.name] = event.target.value 
    setEditFields(editFieldsTemp)
    //console.log(editFieldsTemp)
  }

    const TableRow = (props) => {
      return (
        <tr>
          <td>
            <span className="fw-normal">
              <p class="fs-6 mb-0 pb-0">
                {new Date(props.tx_timestamp * 1000).toLocaleString(
                  "en-US"
                )}
              </p>
            </span>
          </td>
          <td>
            <Container>
              <Row>
                <span>{props.tx_hash_short} 
                  <CopyToClipboard text={props.tx_hash}>
                    <FontAwesomeIcon icon={faCopy} className="ms-2"/> 
                  </CopyToClipboard>
                  { props.chain_id == -2013 ?
                    <a href={"https://explorer.radixdlt.com/#/transactions/" + props.tx_hash} target="_blank">
                      <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2"/>
                    </a>
                    :<></>
                  }
                </span>
              </Row>
              <Row>
                <p class="fs-6 mb-0 pb-0">{props.tx_failed == 'Y' ? "Tx Failed" : ""}</p>
              </Row>
            </Container>
          </td>
          
          <td>
            {
              props.amount_send_value > 0 ? 
              <span >
                <p class="fs-6 mb-0 pb-0">{(parseFloat(props.amount_send_value)).toLocaleString('en-US')} {props.send_token_symbol.toUpperCase()} (${(parseFloat(props.amount_send_value_usd)).toFixed(2).toLocaleString('en-US')})</p>
                <p class="fs-6 mb-0 pb-0"><img src={props.send_token_icon_url} height={25} className="mb-1" />{props.send_token_name}</p>
              </span>
              : ''
            }
          </td>
          <td>
          {
              props.amount_recv_value > 0 ? 
              <span className="fw-normal">
                <p class="fs-6 mb-0 pb-0">{(parseFloat(props.amount_recv_value)).toLocaleString('en-US')} {props.recv_token_symbol.toUpperCase()} (${(parseFloat(props.amount_recv_value_usd)).toFixed(2).toLocaleString('en-US')})</p>
                <p class="fs-6 mb-0 pb-0"><img src={props.recv_token_icon_url} height={25} className="mb-1" /> {props.recv_token_name}</p>
              </span>
              : ''
          }
          </td>
          <td>
            {
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => {handleTransactionEdit(props)}}>
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            }
          </td>
        </tr>
      );
    };
  
    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Timestamp</th>
                <th className="border-bottom">TX Hash</th>
                <th className="border-bottom">Send</th>
                <th className="border-bottom">Receive</th>
                <th className="border-bottom"></th>
              </tr>
            </thead>
            <tbody>
              {table_props.countPageItems > 0 ? table_props.items.map((t,i) => <TableRow key={i} {...t} />) : <tr/> }
            </tbody>
          </Table>
  
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              { table_props.countTotalItems < 30 ? <p></p>
                  : <Pagination className="mb-2 mb-lg-0">
                  {
                    table_props.pages.map(page => (
                      page == table_props.nrActivePage 
                      ? <Pagination.Item active>{page}</Pagination.Item>
                      : <Pagination.Item onClick={() => {table_props.pageClickHandler(page)}} >{page}</Pagination.Item>
                    ))
                  }
                </Pagination>
              }
            </Nav>
            <small className="fw-bold">
              Showing <b>{table_props.countPageItems}</b> out of <b>{table_props.countTotalItems}</b> entries
            </small>
          </Card.Footer>
        </Card.Body>

        <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleDlgClose}>
          <Modal.Header>
            <Modal.Title className="h6">Edit Transaction</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleDlgClose} />
          </Modal.Header>
          <Modal.Body>
            <EditTransaction 
              txToEdit={txToEdit}
              inputChangeHandler={handleInputChange}/>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={handleDlgClose}>Cancel</Button>
              <Button variant="primary" onClick={() => {
                  handleDlgSave(table_props, txToEdit, editFields);
                  }
              }>Save</Button>
          </Modal.Footer>
        </Modal>
      </Card>
    );
  };