import React, { useRef, useState } from "react";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import Mailjet from "node-mailjet";
import ReCAPTCHA from "react-google-recaptcha";

export default () => {

  const [formFields, setFormFields] = useState({});
  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = React.createRef();

  const handleInputChange = (event) => {
    //setPuba({puba: event.target.value});
    var formFieldsTemp = formFields
    formFieldsTemp[event.target.name] = event.target.value 
    setFormFields(formFieldsTemp)
  }
  
  const verify = async () =>{
  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    const captchaToken = captchaRef.current.getValue();
    
    var data = {
        service_id: 'service_q025u36',
        template_id: 'template_nrr6h1e',
        user_id: '2ZedJuYX7suN--rrD',
        template_params: {
            'user_name': formFields['user_name'],
            'user_email': formFields['user_email'],
            'message': formFields['message'],
            'g-recaptcha-response': captchaToken
        }
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data)
    };

    //addAccount/:chain_id/:public_address/:comment/:is_business/:public_address_name
    var url = 'https://api.emailjs.com/api/v1.0/email/send'
    
    fetch(url, requestOptions)
    .then((response) => {
      console.log(response.status)
      console.log(response)
      if(response.status === 200)
      { 
        console.log("contact send success") 
      }
      else 
      { 
        console.log("contact send failed")
      }
    })
  }

  return (
    <>
      <Row>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h4>Impressum</h4>
          </div>
        </div>
      </Row>
      <Row>
        <div>
          <h5>Angaben gem&auml;&szlig; &sect; 5 TMG:</h5> 
          <p>RUPINE Labs GmbH
            <br /> Holzbachstr. 19
            <br /> 86356 Neusäß
            <br /><br /> Registergericht: Amtsgericht Augsburg 
            <br /> Registernummer: HRB 37244
            <br /> Vertretungsberechtigter Geschäftsführer: Dr. Benjamin Ruf
            <br /> Umsatzsteuer-Identifikationsnummer nach § 27a UStG:
            <br /> DE351535053
            <br />
          </p> 
          <h5>Kontakt:</h5> 
          <Form onSubmit={handleSubmit}>
              <Form.Group id="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control name='user_name' className="mb-3" required type="text" placeholder="Name" onChange={handleInputChange}/>
              </Form.Group>
              <Form.Group id="name">
                <Form.Label>Email</Form.Label>
                <Form.Control name='user_email' className="mb-3" required type="text" placeholder="Email" onChange={handleInputChange}/>
            </Form.Group>
              <Form.Group id="name">
                <Form.Label>Message</Form.Label>
                <Form.Control name='message' className="mb-3" as="textarea" rows={5} onChange={handleInputChange} />
            </Form.Group>
            <ReCAPTCHA 
              sitekey="6Le0QnQiAAAAAO5GIqtAUGOJ4sfpAZvXj9Fogg8q" 
              size="normal"
              ref={captchaRef}
              onChange={verify} />
            <Button variant="primary" type="submit">Submit</Button>
          </Form>
        </div>
      </Row>
    </>
  );
};
