import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFilter, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, DropdownButton, Spinner } from '@themesberg/react-bootstrap';

import { StakingHistoryTable } from "./tables/StakingHistoryTable";
import useRupineAPI from "../data/RupineAPI";
import useHasuraAPI from "../data/HasuraAPI";

export default () => {

  
  // loading component
  const { getWalletData, getStakingHistoryData } = useHasuraAPI();

  // Overall states 
  const [year, setYear] = useState('2023');

  // State for wallet drop down
  const [wallets, setWallets] = useState([]);
  const [countWallets, setCountWallets] = useState(1);
  const [selectedWallet, setSelectedWallet] = useState(1);
  const [selectedWalletStr, setSelectedWalletStr] = useState(1);

  // Stating History
  const [items, setItems] = useState([]);
  const [countTotalItems, setCountTotalItems] = useState(0);
  const [countPageItems, setCountPageItems] = useState([0]);
  const [pages, setPages] = useState(0);
  const [nrActivePage, setNrActivePage] = useState(1);

  // Loading state
  const [loadingTx, setLoadingTx] = useState(true);

  useEffect(() => {

    async function fetchData() {

      // Set wallet data
      var walletData = await getWalletData(30, 0)
      
      if (walletData.address_account.length > 0)
      {
        
        await LoadStakingHistoryData(year)
        
        setSelectedWallet(walletData.address_account[0].public_address)
        setSelectedWalletStr(walletData.address_account[0].public_address_name + ' (' + 
                            walletData.address_account[0].public_address_short + ')')
      }

      setWallets(walletData.address_account)
      setCountWallets(walletData.address_account.length)

      setLoadingTx(false)
    }

    fetchData();
    return () => {};
  }, []);

  const LoadStakingHistoryData = async (year) => 
  {
    console.log('LoadStakingData')
    setLoadingTx(true)

    var limit = 365;
    var offset = 0;
    var data = await getStakingHistoryData(limit, offset, year);

    if (data == null)
    {
      setLoadingTx(false)
      return;
    }

    console.log(data.items)
    setItems(data.items);
    setCountPageItems(data.items.length);
    setCountTotalItems(data.items.length);
    setLoadingTx(false)
  }

  const pageClickHandler = async(page) => {
    setLoadingTx(true);
    LoadStakingHistoryData(year);
  }

  const onSelectHandler = async(eventKey, event) => {
    //console.log('onSelectHandler')
    //console.log(eventKey)
    setLoadingTx(true);
    setSelectedWallet(eventKey)
    //LoadStakingHistoryData(eventKey, 1);
  }

  const onYearChange = async(eventKey, event) => {
    console.log('onYearChange')
    console.log(eventKey)

    setYear(eventKey)
    await LoadStakingHistoryData(eventKey);
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4><img src='https://assets.radixdlt.com/icons/icon-xrd-32x32.png' height={25} className="mb-1" ></img> Staking</h4>
          <p>Available for Radix DLT</p>
          <DropdownButton id="dropdown-year" title={year} onSelect={onYearChange}>
            <Dropdown.Item eventKey='2023'>2023</Dropdown.Item>
            <Dropdown.Item eventKey='2022'>2022</Dropdown.Item>
            </DropdownButton>
        </div>
      </div>

      <div>
      {/* 
        loadingTx ? 
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
          :
          countWallets > 1 ?
            <Dropdown as={ButtonGroup} onSelect={(eventKey, event) => onSelectHandler(eventKey, event)} >
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faFilter} /> {selectedWalletStr}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-md dropdown-menu-right">
              {/*wallets.map((w) => <Dropdown.Item onClick={walletClickHandler(w.public_address)} className="fw-bold text-dark">{w.public_address_name} ({w.public_address_short})</Dropdown.Item>)*//*}
              {wallets.map((w) => <Dropdown.Item className="fw-bold text-dark" eventKey={w.public_address}>{w.public_address_name} ({w.public_address_short})</Dropdown.Item>)}
              </Dropdown.Menu>
            </Dropdown>
          : <p></p>
          
        */}
      </div>

      { loadingTx ? <p></p>
      :
        <StakingHistoryTable 
          items={items} 
          countTotalItems={countTotalItems}
          countPageItems={countPageItems}
          pages={pages} 
          nrActivePage={nrActivePage}
          pageClickHandler={pageClickHandler}/>
      }
    </>
  );
};
