
import React, { useEffect, useState } from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCopy, faAngleUp, faEdit, faEllipsisH, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Nav, Card, Button, Table, Dropdown, Pagination, ButtonGroup, Modal } from '@themesberg/react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";
import { propTypes } from "@themesberg/react-bootstrap/lib/esm/Image";

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return (
    value ? <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}{suffix}
      </span>
    </span> : "--"
  );
};

export const WalletTable = (props) => {

  const { getAccessTokenSilently, getIdTokenClaims  } = useAuth0();
  const [removeId, setRemoveId] = useState(0);
  const [showDefault, setShowDefault] = useState(false);

  const handleRemoveCloseCancel = () => setShowDefault(false);
  const handleRemoveCloseConfirm = async () => 
  { 
    setShowDefault(false);
    props.RemoveHandler(removeId);
  }

  const TableRow = (rowProps) => {

    return (
      <tr className={rowProps.public_address == props.selectedWallet.public_address ? "bg-light" : ""}>
        <td onClick={() => {props.ClickHandler(rowProps)}}>
            <Container>
              <Row>
                <p class="fs-6 mb-0 pb-0"><img src={rowProps.icon_url} width={25} className="mb-3" /> {rowProps.public_address_name} (${rowProps.symbol})</p>
              </Row>
              <Row>
                <CopyToClipboard text={rowProps.public_address}>
                  <span>{rowProps.public_address_short} <FontAwesomeIcon icon={faCopy}/> </span>
                </CopyToClipboard>
              </Row>
            </Container>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => {props.EditHandler(rowProps, false)}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item onClick={() => {props.EditHandler(rowProps, true)}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={() => {setShowDefault(true); setRemoveId(rowProps.id)}}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Wallets</th>
            </tr>
          </thead>
          <tbody>
            { props.totalWallets > 0 ? props.wallets.map(t => <TableRow key={t.id}{...t}/>) : <tr/> }
          </tbody>
        </Table>
        <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleRemoveCloseCancel}>
        <Modal.Header>
          <Modal.Title className="h6">Confirm Deletion</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleRemoveCloseCancel} />
        </Modal.Header>
        <Modal.Body>
          <p>Do you really want to remove this wallet?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRemoveCloseConfirm}>
            Yes
        </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={handleRemoveCloseCancel}>
            Cancel
        </Button>
        </Modal.Footer>
      </Modal>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            { props.totalWallets < 30 ? <p></p>
              : <Pagination className="mb-2 mb-lg-0">
                {
                  props.pages.map(page => (
                    page == props.activePage 
                    ? <Pagination.Item active>{page}</Pagination.Item>
                    : <Pagination.Item>{page}</Pagination.Item>
                  ))
                }
              </Pagination>
            }
          </Nav>
          <small className="fw-bold">
            Showing <b>{props.pageWallets}</b> out of <b>{props.totalWallets}</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};
