import React, { useContext } from 'react'
import { UserContext } from "../UserContext"
import { useAuth0 } from "@auth0/auth0-react"
import { Button } from '@themesberg/react-bootstrap';

export default function MyAuth0Logout() {

  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();

  return isAuthenticated && (
      <Button onClick={() => { logout({ returnTo: window.location.origin }); }} variant="primary" className="m-1">Log out</Button>
  )
}