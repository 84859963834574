import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFilter, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Table, Spinner, Card, Dropdown, DropdownButton, Row, Col, Container } from '@themesberg/react-bootstrap';

import { TaxTradesTable } from "../pages/tables/TaxTradesTable";
import { TaxStakingTable } from "../pages/tables/TaxStakingTable";
import useHasuraAPI from "../data/HasuraAPI";

export default () => {

  // loading component
  const { getTaxTradesData, getTaxFifosData, getStakingHistoryData } = useHasuraAPI();

  // Overall states 
  const [year, setYear] = useState('2023');

  // States for Trade Table
  const [items, setItems] = useState([]);
  const [countTotalItems, setCountTotalItems] = useState(0);
  const [profitLoss, setProfitLoss] = useState([0]);

  // States for Staking Table
  const [itemsStaking, setItemsStaking] = useState([]);
  const [countTotalItemsStaking, setCountTotalItemsStaking] = useState(0);
  const [profitLossStaking, setProfitLossStaking] = useState([0]);

  // Loading state
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {

    async function fetchData() {

      setLoadingData(true)

      await ReloadReport(year)
        
      setLoadingData(false)
    }

    fetchData();
    return () => {};
  }, []);

  const ReloadReport = async (_year) => 
  {
    await LoadTaxTradesData(_year);
    await LoadStakingHistoryData(_year)
  }

  const LoadTaxTradesData = async (_year) => 
  {

      var limit = 100000; // 100k
      var offset = 0;
      var data = await getTaxTradesData(limit, offset, _year);
      console.log('LoadTaxTradesData')
      console.log(data)

      if (data == null)
      {
        setLoadingData(false)
        //console.log('LoadTaxTradesData - data = null')
        return;
      }

      let tradesByCoin = {};
      data.taxTrades.forEach((el) => {
        if(!(el.token_id in tradesByCoin))
        {
          tradesByCoin[el.token_id] = [];
        }
        tradesByCoin[el.token_id].push(el);
      })
      //console.log(tradesByCoin)

      //setItems(data.taxTrades);
      setItems(tradesByCoin);
      setCountTotalItems(data.taxTrades.length);
      setProfitLoss(data.aggregate.sum.profit_or_loss)
      setLoadingData(false)
  }

  const LoadStakingHistoryData = async (_year) => 
  {

    var limit = 365;
    var offset = 0;
    console.log(_year)
    var data = await getStakingHistoryData(365, 0, _year);
    console.log('LoadStakingData')
    console.log(data)

    if (data == null)
    {
      setLoadingTx(false)
      return;
    }

    setItemsStaking(data.items);
    setCountTotalItemsStaking(data.aggregate.count);
    setLoadingTx(false)
  }

  const pageClickHandler = async(page) => {
    //console.log('pageClickHandler')
    setLoadingData(true);
    LoadTaxTradesData(page);
  }

  const onSelectHandler = async(eventKey, event) => {
    //console.log('onSelectHandler')
    //console.log(eventKey)
    LoadTaxFifosData(eventKey)
  }

  const onYearChange = async(eventKey, event) => {
    console.log('onYearChange')
    console.log(eventKey)

    setYear(eventKey)
    await ReloadReport(eventKey);
  }

  const TableRow = (props) => {
    return (
      <tr>
        <td>
          <span className="fw-normal">
            <p class="mb-0 pb-0">{parseFloat(props.amount).toFixed(8)}</p>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <p class="fs-6 mb-0 pb-0">{new Date(props.buy_datetime * 1000).toLocaleDateString()}</p>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <p class="fs-6 mb-0 pb-0">{new Date(props.sell_datetime * 1000).toLocaleDateString()}
            </p>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <p class="fs-6 mb-0 pb-0">{parseInt((new Date(props.sell_datetime * 1000) - new Date(props.buy_datetime * 1000)) / 86400000)} Days</p>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <p class="mb-0 pb-0">$ {parseFloat(props.buy_value_usd).toFixed(2)}</p>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <p class="mb-0 pb-0">$ {parseFloat(props.sell_value_usd).toFixed(2)}</p>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <p class="mb-0 pb-0">$ {parseFloat(props.fees_value_usd).toFixed(2)}</p>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {
              <p class="mb-0 pb-0">${parseFloat(props.profit_or_loss).toFixed(2)}</p>
            }
          </span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Tax Report</h4>
            <DropdownButton id="dropdown-year" title={year} onSelect={onYearChange}>
            <Dropdown.Item eventKey='2023'>2023</Dropdown.Item>
            <Dropdown.Item eventKey='2022'>2022</Dropdown.Item>
            </DropdownButton>
          
          {/*
          <Card className="bg-secondary text-white" >
            <Card.Body >
              <p class="mb-0 pb-0">NOTE: these Features will be available soon</p>
              <p class="mb-0 pb-0">  - historical prices</p>
              <p class="mb-0 pb-0">  - Fee consideration</p>
            </Card.Body>
          </Card>
          */}
        </div>
      </div>

      { loadingData ? 
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        :
        <div>
          <Card border="light" className="table-wrapper table-responsive shadow-sm">
          <Card.Body className="pt-0">
            <h5 className="mt-3">Trades</h5>
            {

              Object.keys(items).map(function (key) {
                var item = items[key]
                //console.log(key)
                //console.log(item)
              })
            }
            {
              Object.keys(items).map((key) => 
              <div className="my-5">
                <p>Asset: {items[key][0].token_name} ({items[key][0].token_symbol})</p>
                <Table hover className="user-table align-items-center">
                  <thead>
                    <tr>
                      <th className="border-bottom">Amount</th>
                      <th className="border-bottom">Buy Date</th>
                      <th className="border-bottom">Sell Date</th>
                      <th className="border-bottom">Holding period</th>
                      <th className="border-bottom">Buy Value USD</th>
                      <th className="border-bottom">Sell Value USD</th>
                      <th className="border-bottom">Fee USD</th>
                      <th className="border-bottom">Profit / Loss</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        items[key].map(t => <TableRow key={t.id}{...t}/>) 
                    }
                  </tbody>
                </Table>
                </div>
              )
            }
          </Card.Body>
        </Card>
        <Card border="light" className="mt-3 table-wrapper table-responsive shadow-sm">
          <Card.Body className="pt-0">
            <h5 className="mt-3">Staking</h5>
            <TaxStakingTable
              items={itemsStaking} 
              countPageItems={countTotalItemsStaking}
            />
          </Card.Body>
        </Card>
        <Card border="light" className="mt-3 table-wrapper table-responsive shadow-sm">
          <Card.Body className="pt-0">
            <h5 className="mt-3">Airdrops</h5>
            <p>UNDER CONSTRUCTION</p>
          </Card.Body>
        </Card>
        <Card border="light" className="mt-3 table-wrapper table-responsive shadow-sm">
          <Card.Body className="pt-0">
            <h5 className="mt-3">Liquidity Mining</h5>
            <p>UNDER CONSTRUCTION</p>
          </Card.Body>
        </Card>
        <Card border="light" className="mt-3 table-wrapper table-responsive shadow-sm">
          <Card.Body className="pt-0">
            <h5 className="mt-3">Lending</h5>
            <p>UNDER CONSTRUCTION</p>
          </Card.Body>
        </Card>
      </div>
      }
    </>
  );
};
