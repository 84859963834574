import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Modal, Spinner, Container } from '@themesberg/react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";

import { UploadTable } from "./tables/UploadTable";
import { ManualTxEditTable } from "./tables/ManualTxEditTable";
import useRupineAPI from "../data/RupineAPI";
import useHasuraAPI from "../data/HasuraAPI";
import { chain, isArguments } from "lodash";

export default () => {
  
  // file upload
  const [file, setFile] = useState(null);
  const [csvType, setCsvType] = useState("1");
  const [base64str, setBase64str] = useState(null);
  const [filePath, setFilePath] = useState(null);
  
  // loading component
  const { uploadCEXCSV, getUploads, InsertJob } = useHasuraAPI();
  
  // States for File Table
  const [files, setFiles] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0);
  const [filePages, setFilePages] = useState([1]);
  const [pageFiles, setPageFiles] = useState(0);
  const [selectedFile, setSelectedFile] = useState(1);

    // States for Manual Transacion Table
    const [items, setItems] = useState([]);
    const [countTotalItems, setCountTotalItems] = useState(0);
    const [countPageItems, setCountPageItems] = useState([0]);
    const [pages, setPages] = useState([]);
    const [nrActivePage, setNrActivePage] = useState(1);

  // Loading state
  const [loading, setLoading] = useState(true);


  useEffect(() => {

    async function fetchData() {

      await LoadData();

    }

    fetchData();
    return () => {};
  }, []);

  const LoadData = async () => {
    const limitFiles = 30;
    const offset = 0;
    const data = await getUploads();

    if(data == null ){
      setLoading(false);
      return;
    }

    setFiles(data.files)
    setPageFiles(data.files.length);
    setTotalFiles(data.aggregate.count);
    var _pageCount = Math.ceil(data.aggregate.count / limitFiles);
    let _pages = [];
    for (let i = 1; i <= _pageCount; i++) {
        _pages = _pages.concat(i);
    }
    setFilePages(_pages)
    
    setLoading(false)
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const onSubmitCSV = async (e) => {
    e.preventDefault() // stop form submit

    //console.log(e)
    //console.log('Upload')
    //console.log(file.name)
    //console.log(file.type)
    //console.log(base64str)
    //console.log(csvType)
    setLoading(true);
    const reply = await uploadCEXCSV("Portfolio 1", file.name, file.type, csvType, base64str);

    if(reply != null){
      await LoadData();
    }
    setLoading(false);
    /*
    if(reply != null){
      files.push({
          account_name: "Portfolio 1"
        , account_no: "google-oauth2|108116362894634667900"
        , created_at: 1671689470
        , filename: "HISTORY_6310aa66075a310007a4fb7b.csv"
        , filetype: "text/csv"
        , id: 5
        , modified_at: 1671689470
      })
    }
    */
  }

  const onChangeCSV = async (e) => {
    setFile(e.target.files[0]);
    if (e.target.files[0]) {
      const base64 = await convertBase64(e.target.files[0]);
      setBase64str(base64);
    }
  }

  const onChangeCsvtype = async (e) => {
    setCsvType(e.target.value);
  }

  const RemoveFile = async (removeProps) => 
  { 
      console.log("UploadCenter - RemoveFile")
      console.log(removeProps)
      InsertJob("removeCexCsv", "Portfolio 1," + removeProps.id)
  }
  
  const selectFileHandler = async(file) => {
    //setLoading(true)
    setSelectedFile(file)
    //LoadManualTransactionsData(file.filename, "", 1)
  }

  const LoadManualTransactionsData = async (p) => 
  {
      // Reload
      setNrActivePage(p);

      var limit = 30;
      var offset = (p-1) * limit;
      var data = await getTransactionData(limit, offset, public_address);

      if (data == null)
      {
        setLoadingTx(false)
        return;
      }

      setItems(data.transaction);
      setCountPageItems(data.transaction.length);
      setCountTotalItems(data.aggregate.count);
      var _pageCount = Math.ceil(data.aggregate.count / limit);
      let _pages = [];
      let start_index = 1;
      let end_index = _pageCount;
      if (p > 2){
        start_index = p - 2;
      } 
      if (_pageCount > (p+2)){
        end_index = p + 2;
      }
      if(end_index - start_index < 4){
        end_index = start_index + 4;
      }


      for (let i = start_index; i <= end_index; i++) {
          _pages = _pages.concat(i);
      }
      setPages(_pages)
      setLoadingTx(false)
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Imports</h4>
        </div>
      </div>

      { 
        loading ? 
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        :
        <Container>
          <Row><h5>Central Exchange (CSV)</h5></Row>
          <Row>
            <Form onSubmit={onSubmitCSV}>
              <Container className="mb-3">
                <Row>
                  <Col md={4}><input type='file' onChange={onChangeCSV}></input></Col>
                  <Col md={4}>
                    <Form.Select defaultValue={csvType} name='csvType' onChange={onChangeCsvtype}>
                        <option value="1">Kucoin (Trades)</option>
                        {/*
                          <option value="2">Kucoin (Widthdrawls)</option>
                          <option value="3">Kucoin (Deposits)</option>
                          <option value="4">Coinbase</option>
                        */}
                    </Form.Select>
                  </Col>
                  <Col md={4}> <Button type="submit">Upload</Button></Col>
                </Row>
              </Container>
            </Form>
          </Row>
          <Row>
            <Col>
              <UploadTable
                files={files}
                selectedFile={selectedFile}
                totalFiles={totalFiles} 
                pageFiles={pageFiles}
                pages={filePages} 
                activePage={1}
                ClickHandler={selectFileHandler}
                RemoveHandler={RemoveFile}/>
            </Col>
          </Row>
        </Container>
      }
    </>
  );
};
