
import React, { useEffect, useState } from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCopy, faAngleUp, faEdit, faEllipsisH, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Nav, Card, Button, Table, Dropdown, Pagination, ButtonGroup, Modal } from '@themesberg/react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";


export const UploadTable = (props) => {

  const { getAccessTokenSilently, getIdTokenClaims  } = useAuth0();
  const [removeProps, setRemoveProps] = useState({});
  const [showDefault, setShowDefault] = useState(false);

  const handleRemoveCloseCancel = () => setShowDefault(false);
  const handleRemoveCloseConfirm = async () => 
  { 
    setShowDefault(false);
    console.log('remove upload')
    props.RemoveHandler(removeProps);
  }

  const TableRow = (rowProps) => {

    return (
      <tr className={rowProps.filename == props.selectedFile.filename ? "bg-light" : ""}>
        <td onClick={() => {props.ClickHandler(rowProps)}}>
            <Container>
              <Row>
                <p class="fs-6 mb-0 pb-0">{new Date(rowProps.modified_at * 1000).toLocaleString()}</p>
                <p class="fs-6 mb-0 pb-0">{rowProps.filename} </p>
              </Row>
            </Container>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className="text-danger" onClick={() => {
                  setShowDefault(true); setRemoveProps({'id': rowProps.id, 'filename': rowProps.filename})
                }}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">File</th>
              <th className="border-bottom"></th>
            </tr>
          </thead>
          <tbody>
            { props.pageFiles > 0 ? props.files.map(t => <TableRow key={t.id}{...t}/>) : <tr/> }
          </tbody>
        </Table>
        <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleRemoveCloseCancel}>
        <Modal.Header>
          <Modal.Title className="h6">Confirm Deletion</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleRemoveCloseCancel} />
        </Modal.Header>
        <Modal.Body>
          <p>Deleting the file will also delete the imported transactions. Do you want to schedule the file removal now?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRemoveCloseConfirm}>
            Yes
        </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={handleRemoveCloseCancel}>
            Cancel
        </Button>
        </Modal.Footer>
      </Modal>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            { props.totalFiles < 30 ? <p></p>
              : <Pagination className="mb-2 mb-lg-0">
                {
                  props.pages.map(page => (
                    page == props.activePage 
                    ? <Pagination.Item active>{page}</Pagination.Item>
                    : <Pagination.Item>{page}</Pagination.Item>
                  ))
                }
              </Pagination>
            }
          </Nav>
          <small className="fw-bold">
            Showing <b>{props.pageFiles}</b> out of <b>{props.totalFiles}</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};
