import React, { useState, useEffect } from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, Card } from '@themesberg/react-bootstrap';

import useRupineAPI from "../../data/RupineAPI";

export const EditTransaction = (props) => {

  return (
    <>
            <Form>
              <Row>
                  <Col md={6} className="mb-3">
                    <p class="fs-6 mb-0 pb-0">Timestamp</p>
                  </Col>
                  <Col md={6} className="mb-3">
                  <span className="fw-normal">
                    <p class="fs-6 mb-0 pb-0">
                      {new Date(props.txToEdit.tx_timestamp * 1000).toLocaleString(
                        "en-US"
                      )}
                    </p>
                  </span>
                  </Col>
              </Row>
            {
              props.txToEdit.amount_send_value > 0 ? 
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Label>Send</Form.Label>
                    <p class="fs-6 mb-0 pb-0">{(parseFloat(props.txToEdit.amount_send_value)).toLocaleString('en-US')} {props.txToEdit.send_token_symbol.toUpperCase()}</p>
                    <p class="fs-6 mb-0 pb-0"><img src={props.txToEdit.send_token_icon_url} height={25} className="mb-1" />{props.txToEdit.send_token_name}</p>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Label>Value USD</Form.Label>
                    <span >
                      <Form.Control required type="text" placeholder={props.txToEdit.amount_send_value_usd} name='amount_send_value_usd' onChange={props.inputChangeHandler}></Form.Control>
                    </span>
                  </Col>
                </Row>
              :null
            }
            {
              props.txToEdit.amount_recv_value > 0 ? 
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Label>Received</Form.Label>
                    <p class="fs-6 mb-0 pb-0">{(parseFloat(props.txToEdit.amount_recv_value)).toLocaleString('en-US')} {props.txToEdit.recv_token_symbol.toUpperCase()}</p>
                    <p class="fs-6 mb-0 pb-0"><img src={props.txToEdit.recv_token_icon_url} height={25} className="mb-1" /> {props.txToEdit.recv_token_name}</p>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Label>Value USD</Form.Label>
                    <span >
                      <Form.Control required type="text" placeholder={props.txToEdit.amount_recv_value_usd} name='amount_recv_value_usd' onChange={props.inputChangeHandler}></Form.Control>
                    </span>
                  </Col>
                </Row>
              :null
            }
            {
              props.txToEdit.amount_send_value > 0 ? 
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Label>Fee</Form.Label>
                    <p class="fs-6 mb-0 pb-0">{(parseFloat(props.txToEdit.amount_fee_value)).toLocaleString('en-US')}</p>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Label>Value USD</Form.Label>
                    <span >
                      <Form.Control required type="text" placeholder={props.txToEdit.amount_fee_value_usd} name='amount_fee_value_usd' onChange={props.inputChangeHandler}></Form.Control>
                    </span>
                  </Col>
                </Row>
              :null
            }
            <Row className="align-items-center">
                <Col md={6} className="mb-3">
                    <Form.Label>Category</Form.Label>
                </Col>
                <Col md={6} className="mb-3">
                      <Form.Select defaultValue={props.txToEdit.tx_type_no} name='category' onChange={props.inputChangeHandler}>
                        <option value="010-010">Transfer</option>
                        <option value="010-020">Swap (Buy / Sell)</option>
                        <option value="010-040">Airdrop</option>
                        <option value="010-050">Reward</option>
                        <option value="020-010">Stake</option>
                        <option value="020-011">Unstake</option>
                        <option value="030-070">Take Loan</option>
                        <option value="030-080">Payback Loan</option>
                        <option value="030-110">Interest</option>
                        <option value="040-010">Add Liquidity</option>
                        <option value="040-020">Remove Liquidity</option>
                        <option value="010-012">Donation</option>
                        <option value="010-013">Gift</option>
                      </Form.Select>
                </Col>
              </Row>
            </Form>
    </>
  );
};
