import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFilter, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Card, Spinner } from '@themesberg/react-bootstrap';

import { TaxTradesTable } from "../pages/tables/TaxTradesTable";
import useHasuraAPI from "../data/HasuraAPI";

export default () => {

  // loading component
  const { getTaxTradesData, getTaxFifosData } = useHasuraAPI();

  // States for Trade Table
  const [items, setItems] = useState([]);
  const [countTotalItems, setCountTotalItems] = useState(0);
  const [countPageItems, setCountPageItems] = useState([0]);
  const [profitLoss, setProfitLoss] = useState([0]);
  const [pages, setPages] = useState(0);
  const [nrActivePage, setNrActivePage] = useState(1);

  // Loading state
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {

    async function fetchData() {

      setLoadingData(true)

      await LoadTaxTradesData(1)
        
      setLoadingData(false)
    }

    fetchData();
    return () => {};
  }, []);

  const LoadTaxFifosData = async (token_id) => 
  {
    var data = await getTaxFifosData(token_id);
    console.log(data);
    setFifoItems(data.items)
  }

  const LoadTaxTradesData = async (p) => 
  {
    //console.log('LoadTaxTradesData')

      setNrActivePage(p);

      var limit = 30;
      var offset = (p-1) * limit;
      var data = await getTaxTradesData(limit, offset);
      //console.log(data)

      if (data == null)
      {
        setLoadingData(false)
        //console.log('LoadTaxTradesData - data = null')
        return;
      }

      setItems(data.taxTrades);
      setCountPageItems(data.taxTrades.length);
      setCountTotalItems(data.aggregate.count);
      setProfitLoss(data.aggregate.sum.profit_or_loss)
      var _pageCount = Math.ceil(data.aggregate.count / limit);
      let _pages = [];
      let start_index = 1;
      let end_index = _pageCount;
      if (p > 2){
        start_index = p - 2;
      } 
      if (_pageCount > (p+2)){
        end_index = p + 2;
      }
      if(end_index - start_index < 4){
        end_index = start_index + 4;
      }


      for (let i = start_index; i <= end_index; i++) {
          _pages = _pages.concat(i);
      }
      setPages(_pages)
      setLoadingData(false)
  }

  const pageClickHandler = async(page) => {
    setLoadingData(true);
    LoadTaxTradesData(page);
  }

  const onSelectHandler = async(eventKey, event) => {
    //console.log('onSelectHandler')
    //console.log(eventKey)
    LoadTaxFifosData(eventKey)
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Trades</h4>
          {/*
          <Card className="bg-secondary text-white" >
            <Card.Body >
              <p class="mb-0 pb-0">NOTE: these Features will be available soon</p>
              <p class="mb-0 pb-0">  - historical prices</p>
              <p class="mb-0 pb-0">  - Fee consideration</p>
            </Card.Body>
          </Card>
          */}
          <p> Profit / Loss :</p>
            {
                profitLoss >= 0 
                ? <p class="mb-0 pb-0 text-success">{parseFloat(profitLoss).toFixed(2)}</p>
                : <p class="mb-0 pb-0 text-danger">{parseFloat(profitLoss).toFixed(2)}</p>
            }
          
        </div>
      </div>

      { loadingData ? 
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        :
        <TaxTradesTable 
          items={items} 
          countTotalItems={countTotalItems}
          countPageItems={countPageItems}
          pages={pages} 
          nrActivePage={nrActivePage}
          pageClickHandler={pageClickHandler}/>
      }
    </>
  );
};
