import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFilter, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Container, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Spinner } from '@themesberg/react-bootstrap';

import useRupineAPI from "../data/RupineAPI";
import useHasuraAPI from "../data/HasuraAPI";

export default () => {

  
  // loading component
  const { getWalletData, getStakingHistoryData } = useHasuraAPI();

  // Loading state
  const [loadingTx, setLoadingTx] = useState(true);

  useEffect(() => {

    async function fetchData() {

      setLoadingTx(false)
    }

    fetchData();
    return () => {};
  }, []);

  const handleInputChange = (event) => {
    console.log('profile - handleInputChange')
    //console.log(event)
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Profile & Settings</h4>
        </div>
      </div>

        <Form>
          <Row>
            <Col md="auto" className="mb-3"><Form.Label>Currency</Form.Label></Col>
            <Col md="auto" className="mb-3">
                <Form.Select defaultValue='USD' name='category' onChange={handleInputChange}>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                </Form.Select>
            </Col>
          </Row>
          <Row></Row>
          <Row><Form.Label>FIFO Calculation</Form.Label></Row>
          <Row><Form.Check type="switch" id="custom-switch" label="Tax free after 1 year hodling duration"/></Row>
          <Row><Form.Label>Notifications</Form.Label></Row>
          <Row><Form.Check type="switch" id="custom-switch" label="Enable report sending by email"/></Row>
          <Row><Form.Check type="switch" id="custom-switch" label="Allow sending email notifications"/></Row>
          <Button variant="primary" type="submit">Update</Button>
            
        {/*
        <div className="d-block mb-4 mb-md-0">
          <h4>Portfolios</h4>
          <p>Create portfolio</p>
        </div>
        */}
        </Form>

    </>
  );
};
