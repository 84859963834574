import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faEdit, faEllipsisH, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Nav, Card, Button, Table, Dropdown, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";


const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return (
    value ? <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}{suffix}
      </span>
    </span> : "--"
  );
};

export const BalancesTable = (props) => {

  const TableRow = (props) => {
    return (
      <tr>
        <td>
          <span className="fw-normal">
            <p class="mb-0 pb-0">{props.name}</p>
            <p class="mb-0 pb-0">
              <img src={props.icon_url} height={25} className="mb-1" /> ${props.symbol ? props.symbol.toUpperCase() : null}
            </p>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <p class="mb-0 pb-0">$ {parseFloat(props.value_usd).toFixed(2)}</p>
            <p class="mb-0 pb-0">Price: ${parseFloat(props.price_in_usd).toFixed(8)}</p>
            <p class="mb-0 pb-0">Amount: {(parseFloat(props.amount)).toFixed(2).toLocaleString('en-US')}</p>
          </span>
        </td>
          {/*
        <td>
          <span className="fw-normal">
            {
              props.current_profit >= 0 
                ? <p class="mb-0 pb-0 text-success">$ {parseFloat(props.current_profit).toFixed(2)}</p>
                : <p class="mb-0 pb-0 text-danger">$ {parseFloat(props.current_profit).toFixed(2)}</p>
            }
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger">
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
          */}
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Token</th>
              <th className="border-bottom">Balance</th>
            </tr>
          </thead>
          <tbody>
            {props.totalBalances > 0 ? props.balances.map(t => <TableRow key={t.id}{...t}/>) : <tr/> }
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            { props.totalBalances < 30 ? <p></p>
                : <Pagination className="mb-2 mb-lg-0">
                {
                  props.pages.map(page => (
                    page == props.activePage 
                    ? <Pagination.Item active>{page}</Pagination.Item>
                    : <Pagination.Item onClick={() => {props.ClickHandler(page)}}>{page}</Pagination.Item>
                  ))
                }
              </Pagination>
            }
          </Nav>
          <small className="fw-bold">
            Showing <b>{props.pageBalance}</b> out of <b>{props.totalBalances}</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};
