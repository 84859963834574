
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Row, Button, Modal, Spinner, Card } from '@themesberg/react-bootstrap';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment'
import useRupineAPI from "../../data/RupineAPI";
import useHasuraAPI from "../../data/HasuraAPI";
import { NewAddress } from "../components/NewAddress";
import { TaxFifoTable } from "../../pages/tables/TaxFifoTable";

import { Redirect } from "react-router-dom";
import { Routes } from "../../routes";

export default () => {

  // loading component
  const { getDataAuthorized, getTaxFifosData, getTaxTradesData } = useRupineAPI();
  
  const { addWallet, getBalanceHistoryUSD } = useHasuraAPI();
  const { isAuthenticated, isLoading, getIdTokenClaims  } = useAuth0();

  // States for FIFO Table
  const [fifoItems, setFifoItems] = useState([]);
  const [countFifoItems, setCountFifoItems] = useState(0);
  const [uniqueTokenIds, setUniqueTokenIds] = useState([]);
  
  // chart data
  const [dailyData, setDailyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [showDefault, setShowDefault] = useState(false);

  const handleDlgClose = () => {
    setShowDefault(false);
    LoadSampleData();
  };
  const handleAddressAdded = async () => { setShowDefault(true) };

  const submitNewAddress = async (chain_id, public_address) => {

    var addSuccess = await addWallet(chain_id, public_address, 'no comment', 'N', 'Wallet')
    if(addSuccess){
        handleAddressAdded();
    }
  }

  const LoadSampleData = async () => 
  {
      var data = await getBalanceHistoryUSD();
    
      if(data == null){
        setDailyData([])
        setLoading(false)
        return;
      }

      const data_temp = [];

      // Convert data into integer
      for (let i = 0; i < data.balance_history_usd.length; i++) {
        data_temp[data.balance_history_usd.length - 1 - i] = {
            x:i
          , timestamp:1*data.balance_history_usd[i].max_timestamp_account
          , y:1*data.balance_history_usd[i].total_usd_account
          , date: new Date(1*data.balance_history_usd[i].max_timestamp_account * 1000).toLocaleDateString("en-US")
        }
        data.balance_history_usd[i].max_timestamp_account = 1*data.balance_history_usd[i].max_timestamp_account;
        data.balance_history_usd[i].total_usd_account = 1*data.balance_history_usd[i].total_usd_account;
      }

      setDailyData(data_temp)
      setLoading(false)
  }

  useEffect(() => {
    LoadSampleData();
    return () => {};
  }, []);

  const formatXAxis = (tickItem) => {
    return tickItem.toString();
  }

  const formatYAxis = (number) => {
    if(number > 1000000000){
      return (number/1000000000).toString() + 'B';
    }else if(number > 1000000){
      return (number/1000000).toString() + 'M';
    }else if(number > 1000){
      return (number/1000).toString() + 'K';
    }else{
      return number.toString();
    }
  }

  const renderLineChart = (
    <ResponsiveContainer width="95%" height={400}>
        <AreaChart
          width={500}
          height={400}
          data={dailyData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={timeStr => moment(timeStr).format('MMM DD')}/>
          <YAxis type="number" domain={['auto', 'auto']} tickFormatter={formatYAxis}/>
          <Tooltip />
          <Area type="monotone" dataKey="y" stroke="#8884d8" fill="#8884d8" />
        </AreaChart>
        </ResponsiveContainer>
  );



  return (
    <div>
      { loading ? 
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        : dailyData.length == 0 ? 
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <h4>Track Wallet</h4>
              {
              isAuthenticated ?
                <NewAddress addHandler={submitNewAddress} />
              : <div>
                  <Card className="bg-info text-white" >
                    <Card.Body >Login to track your wallet. Email sign up need to be verified.</Card.Body>
                  </Card>
                </div>
              }
            </div>
          </div>
        : <div>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <h6>Current Balance: </h6>
              <h4>$ {dailyData.length > 0 ? parseFloat(dailyData[dailyData.length - 1].y).toFixed(2).toLocaleString('en-US') : ''}</h4>
            </div>
          </div>
          
          <div>
            {renderLineChart}
          </div>
        </div>
      }

      {
        /*
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Your Top Assets:</h4>
        </div>
      </div>
      */
      }
      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleDlgClose}>
        <Modal.Header>
          <Modal.Title className="h6">Address added</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleDlgClose} />
        </Modal.Header>
        <Modal.Body>
          <p>Querying data might take several minutes</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDlgClose}>
            Ok
        </Button>
        </Modal.Footer>
      </Modal>
    </div>
    
  );
};
