
import { faHistory, faHandHoldingUsd, faCoins, faChartPie, faMobileAlt, faPenFancy } from "@fortawesome/free-solid-svg-icons";


export default [
    {
        "id": 0,
        "title": "Portfolio Tracking",
        "icon": faChartPie,
        "description": "Keep track of your crypto balance in FIAT"
    },
    {
        "id": 1,
        "title": "TX categorizing",
        "icon": faHandHoldingUsd,
        "description": "Individually categorize transactions for reporting"
    },
    {
        "id": 2,
        "title": "Staking",
        "icon": faCoins,
        "description": "Ddaily overview of your staking rewards on Radix DLT"
    },
    {
        "id": 3,
        "title": "Positions",
        "icon": faHistory,
        "description": "List your current position with hodling duration"
    }
];