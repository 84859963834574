
import React, { useState } from 'react';
import { Col, Row, Form, Button, Card } from '@themesberg/react-bootstrap';


export const NewAddress = (props) => {

    const [puba, setPuba] = useState({puba:''});
    const [chainID, setChainID] = useState({chain_id:'-2013'});

    const handleInputChangePuba = (event) => {
        setPuba({puba: event.target.value});
        //console.log('Puba changed')
    }  

    const handleInputChangeNetwork = async(event) => {
        setChainID({chain_id: +event.target.value});
        //console.log('Chain ID changed')
    }  

    return (
            <Row>
                <Col md={6} className="mb-3">
                    <Form.Control required type="text" placeholder='New Address' name='inputPuba' onChange={handleInputChangePuba}/>
                </Col>
                <Col md={4} className="mb-3">
                <Form.Select defaultValue='-2013' name='inputNetwork' onChange={handleInputChangeNetwork} disabled>
                    <option value="-2013">RadixDLT (XRD)</option>
                    {
                        //<option value="-20178">DeFiChain (DFI)</option>
                    }
                </Form.Select>
                </Col>
                <Col md={2} className="mb-3">
                {
                    <Button variant="primary" type="submit" onClick={() => props.addHandler(chainID.chain_id, puba.puba)}>Submit</Button>
                    //<button type="submit" class="btn btn-primary" onClick={()=>props.addHandler(chainID.chain_id, puba.puba)}>Submit</button>
                }
                </Col>
            </Row>
    );
};
