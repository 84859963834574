import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faExternalLinkAlt, faTimesCircle, faCheckCircle, faCalendarAlt, faCodeBranch, faShoppingCart, faFolder, faMapMarkedAlt, faPager, faFileCode, faDownload } from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Card, Image, Button, Container, ListGroup, Tooltip, OverlayTrigger, Form, Navbar, Nav, Badge } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import GitHubButton from 'react-github-btn';

import { Routes } from "../routes";
import RupineLogoIcon from "../assets/img/rupine_logo_1.png";
import GemHiveLogoIcon from "../assets/img/rupine_logo.png";
import MockupPresentation from "../assets/img/mockup-presentation-rupine.png";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import MapboxImg from "../assets/img/mockup-map-presentation.png";
import CalendarImg from "../assets/img/mockup-calendar-presentation.png";
import ReactMockupImg from "../assets/img/react-mockup.png";
import BS5IllustrationsImg from "../assets/img/illustrations/bs5-illustrations.svg";
import BS5Logo from "../assets/img/technologies/bootstrap-5-logo.svg";
import ReactLogo from "../assets/img/technologies/react-logo.svg";

import pages from "../data/pages";
import features from "../data/features";
import useHasuraAPI from "../data/HasuraAPI";

export default () => {

  const [isChecked, setIsChecked] = useState(false);
  const [formFields, setFormFields] = useState({});
  const [numInputs, setNumInputs] = useState(1);
  const [formData, setFormData] = useState({});

  // loading component
  const { OrderDTokenReport } = useHasuraAPI();
  
  /*
  const handleInputChange = (event) => {
    console.log('Presentation - handleInputChange')
    console.log(event)
    var formFieldsTemp = formFields
    formFieldsTemp[event.target.name] = event.target.value 
    setFormFields(formFieldsTemp)
  }
  */
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddInput = () => {
    setNumInputs(numInputs + 1);
  };
  const handleRemoveInput = () => {
    setNumInputs(numInputs - 1);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = (event) => {
    console.log('Handle Submit')
    event.preventDefault();
    if (!isChecked) {
      alert('Please check "personal use" before submitting the form.');
      return;
    }
    if (!validateEmail(formData['email_address'])) {
      alert('Please enter a valid email address.');
      return;
    }

    var public_address_list = formData['dfi-address-0']

    for (let i=1; i <=100; i++){
      var field_name = 'dfi-address-' + i;
      if(field_name in formData){
        public_address_list += ',' + formData[field_name];
      } else{
        break;
      }
    }

    OrderDTokenReport(formData['email_address'], public_address_list);
  };

  const PagePreview = (props) => {
    const { name, image, link } = props;
    
    return (
      <Col xs={6} className="mb-5">
        <Card.Link as={Link} to={link} className="page-preview page-preview-lg scale-up-hover-2">
          <Image src={image} className="shadow-lg rounded scale" alt="page preview" />

          <div className="text-center show-on-hover">
            <h6 className="m-0 text-center text-white">
              {name} <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </h6>
          </div>
        </Card.Link>
      </Col>
    );
  };

  const Feature = (props) => {
    const { title, description, icon } = props;

    return (
      <Col xs={12} sm={6} lg={3}>
        <Card className="bg-white shadow-soft text-primary rounded mb-4">
          <div className="px-3 px-lg-4 py-5 text-center">
            <span className="icon icon-lg mb-4">
              <FontAwesomeIcon icon={icon} />
            </span>
            <h5 className="fw-bold text-primary">{title}</h5>
            <p>{description}</p>
          </div>
        </Card>
      </Col>
    );
  };

  const inputs = [];
  for (let i = 0; i < numInputs; i++) {
    inputs.push(
      <Row>
        <Col md="3" className="mb-3"><Form.Label><img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/defichain-coin-dfi-icon.png" height={16}/> Wallet Address</Form.Label></Col>
        <Col md="6" className="mb-3"><Form.Control required type="text" placeholder='df1.....' name={`dfi-address-${i}`} onChange={handleInputChange}></Form.Control></Col>
        
        { i == numInputs - 1 
            ? <Col md="auto" className="mb-3"><Button variant="primary" type="submit" onClick={handleAddInput}> + </Button></Col> 
            : <Col md="auto" className="mb-3"><Button variant="warning" type="submit" onClick={handleRemoveInput}>X</Button></Col>
        }
      </Row>
    );
  }

  return (
    <>
    {
    /*
      <Navbar variant="dark" expand="lg" bg="dark" className="navbar-transparent navbar-theme-primary sticky-top">
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand as={HashLink} to="#home" className="me-lg-3 d-flex align-items-center">
            <Image src={ReactHero} />
            <span className="ms-2 brand-text d-none d-md-inline">Volt React</span>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={HashLink} to="#features">Features</Nav.Link>
                <Nav.Link as={HashLink} to="#getting-started">Getting Started</Nav.Link>
                <Nav.Link as={HashLink} to="#download">Upgrade to Pro</Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <Button as={HashLink} to="#download" variant="outline-white" className="ms-3"><FontAwesomeIcon icon={faDownload} className="me-1" /> Download</Button>
          </div>
        </Container>
      </Navbar>
      */
      }
      <div className="bg-primary text-muted fw-light mb-0 h5">
        <Container>
          <Row>
            <Col xs={4} className="text-center">
              <Row>
                <Col md="6" className="text-right px-0">
                <img src="https://assets-global.website-files.com/6053f7fca5bf627283b582c2/6266da24f1cf78c68fb0c215_Radix-Icon-Transparent-400x400.png" height={30}/>
                </Col>
                <Col md="6" className="text-left px-0">
                  <p className="text-muted fw-light mt-1 h5">Radix DLT</p>
                </Col>
              </Row>
            </Col>
            <Col xs={4} className="text-center">
              <Row>
                <Col md="6" className="text-right px-0">
                  <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/defichain-coin-dfi-icon.png" height={16} className="mt-1"/>
                </Col>
                <Col md="6" className="text-left px-1">
                  <p className="text-muted fw-light mt-1 h5">DeFiChain (soon)</p>
                </Col>
              </Row>
            </Col>
            <Col xs={4} className="text-center">
              <Row>
                <Col md="6" className="text-right px-0">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Ethereum-icon-purple.svg/768px-Ethereum-icon-purple.svg.png?20200227011040" height={16} className="mt-1"/>
                </Col>
                <Col md="6" className="text-left px-1">
                  <p className="text-muted fw-light mt-1 h5">Ethereum (soon)</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
        
      <section className="section-header overflow-hidden pt-5 pt-lg-6 pb-9 pb-lg-12 bg-primary text-white" id="home">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              {/*<div className="react-big-icon d-none d-lg-block"><span className="fab fa-react"></span></div>*/}
              <h1 className="fw-bolder text-secondary"><Image src={GemHiveLogoIcon} height={40} width={40} className="mb-2" alt="Rupine Logo" /> Gem Hive</h1>
              <p className="text-muted fw-light mb-5 h5">Crypto portfolio tracking and tax reporting</p>
              <div className="d-flex align-items-center justify-content-center">
                <Button variant="secondary" as={Link} to={Routes.DashboardOverview.path} className="text-dark me-3">
                  start app <FontAwesomeIcon icon={faExternalLinkAlt} className="d-none d-sm-inline ms-1" />
                </Button>
              </div>
              <div className="d-flex justify-content-center flex-column mb-6 mb-lg-5 mt-5">
                <div className="text-center">
                  {/*<a href="https://RupineLabs.com" target="_blank">*/}
                    <Image src={RupineLogoIcon} height={25} width={25} className="mb-3" alt="Rupine Logo" />
                    <p className="text-muted font-small m-0">A Rupine Labs production</p>
                  {/*</a>*/}
                </div>
              </div>
            </Col>
          </Row>
          <figure className="position-absolute bottom-0 left-0 w-100 d-none d-md-block mb-n2">
            <svg className="fill-soft" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
              <path d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z" />
            </svg>
          </figure>
        </Container>
      </section>
      
      <div className="section pt-0">
        <Container className="mt-n10 mt-lg-n12 z-2">

                {/*

          <Row>
          <Card border="dark" className="table-wrapper table-responsive shadow-sm bg-light">
            <Card.Body className="pt-0">
              <Col xs={12} className="text-center">
                <h3 className="fw-bolder text-muted mb-5 h5 mt-3">NEW: Get your DeFiChain dToken Interest Report</h3>
                <p className="lead px-lg-8">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
              </Col>
              <p>1. Fill out the form and submit</p>
              <p>2. Send 35 DFI Tokens to 0x1234</p>
              <p>3. receive a pdf and csv with all your Interest</p>
              <Form onSubmit={handleSubmit}>
                {inputs}
                <Row>
                    <Col md="3" className="mb-3"><Form.Label>Email:</Form.Label></Col>
                    <Col md="6" className="mb-3"><Form.Control required type="text" placeholder='mail@addresse.com' name='email_address' onChange={handleInputChange}></Form.Control></Col>
                </Row>
                {/*
                <Row>
                  <Col md="3" className="mb-3 pt-2"><Form.Label>VAT Country</Form.Label></Col>
                  <Col md="3" className="mb-3">
                    <Form.Select defaultValue='na' name='country'>
                      <option value="na">n/a</option>
                      <option value="Austria">Austria</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="Germany">Germany</option>
                      <option value="Greece">Greece</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Italy">Italy</option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Malta">Malta</option>
                      <option value="Netherlands">Netherlands</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Romania">Romania</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Spain">Spain</option>
                      <option value="Sweden">Sweden</option>
                    </Form.Select>
                    </Col>
                    <Col md="3" className="mb-3 pt-2"><Form.Check type="switch" id="custom-switch" label="Non-EU"/></Col>
                </Row>

                <Row>
                    <Col md="3" className="mb-3"><Form.Label>Personal use</Form.Label></Col>
                    <Col md="1" className="mb-3"><Form.Check type="switch" id="personal_use" label="" checked={isChecked} onChange={handleCheckboxChange}/></Col>
                    <Col md="5" className="mb-3"><Form.Label>(For commercial use contact us @RUPINE_Labs <a href="https://twitter.com/RUPINE_Labs"><FontAwesomeIcon icon={faTwitter}/></a>)</Form.Label></Col>
                </Row>
                <Row>
                  <Button variant="secondary" type="submit">Submit</Button>
                </Row>
              </Form>
            </Card.Body>
          </Card>
          </Row>
          */}


          {/*
          <Row className="justify-content-center mt-5">
            <Col xs={12}>
              <Image src={MockupPresentation} alt="Mockup presentation" />
            </Col>
          </Row>
        */}
        
          <Row className="justify-content-center mt-5">
            <Col xs={12} className="mb-5">
              <Card.Link as={Link} to={pages[4].link} className="page-preview page-preview-lg scale-up-hover-2">
                <Image src={pages[4].image} className="shadow-lg rounded scale" alt="Dashboard page preview" />

                <div className="text-center show-on-hover">
                  <h6 className="m-0 text-center text-white">
                    {pages[4].name} <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
                  </h6>
                </div>
              </Card.Link>
            </Col>
          </Row>

          <Row className="justify-content-center mb-5 mb-lg-6 mt-5">
            <Col xs={12} className="text-center">
              <h2 className="px-lg-5">Awesome Features</h2>
              <p className="lead px-lg-8">You get an overview of your balance, transactions and staking rewards in a FIAT currency. Besides, a FIFO trade calculations shows your profit statement and the hodling duration of the current positions.</p>
            </Col>
          </Row>
          <Row>
            {features.map(feature => <Feature key={`features-${feature.id}`} {...feature} />)}
          </Row>

          <Row className="justify-content-center mb-5 mb-lg-6 mt-5">
            <Col xs={12} className="text-center">
              <h2 className="px-lg-5">
                Sign-up and check your Wallets
              </h2>
              <p className="lead px-lg-10">
                After adding your public wallet address the backend generates your personal trading overview. Just a few minutes and you can browse profit history.  
              </p>
            </Col>
          </Row>
          <Row className="mb-5">
            {pages.map(page => <PagePreview key={`page-${page.id}`} {...page} />)}
          </Row>

        </Container>
      </div>


      <footer className="footer py-6 bg-dark text-white">
        <Container>
        <Row>
          <div class="container">
            <div class="row row-cols-auto">
              <div class="col"><Link to={Routes.Impressum.path}>Impressum</Link></div>
              <div class="col"><Link to={Routes.PrivacyPolicy.path}>PrivacyPolicy</Link></div>
            </div>
          </div>
        </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              <Card.Link href="https://RupineLabs.com" target="_blank" className="d-flex justify-content-center">
                <Image src={RupineLogoIcon} height={35} className="d-block mx-auto mb-3" alt="Rupine Logo" />
              </Card.Link>
              <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                <p className="font-weight-normal font-small mb-0">Copyright © Rupine Labs GmbH <span className="current-year">2022-2023</span>. All rights reserved.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
