import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Nav, Card, Button, Table, Dropdown, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";

export const TaxTradesTable = (props) => {

  const TableRow = (props) => {
    return (
      <tr>
        <td>
          <span className="fw-normal">
            <p class="mb-0 pb-0">{parseFloat(props.amount).toFixed(2)} {props.token_symbol.toUpperCase()}</p>
            <p class="mb-0 pb-0"><img src={props.token_icon_url} height={25} className="mb-1" /> {props.token_name}</p>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {
              props.profit_or_loss >= 0 
              ? <p class="mb-0 pb-0 text-success">${parseFloat(props.profit_or_loss).toFixed(2)}</p>
              : <p class="mb-0 pb-0 text-danger">${parseFloat(props.profit_or_loss).toFixed(2)}</p>
            }
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <p class="mb-0 pb-0">$ {parseFloat(props.buy_value_usd).toFixed(2)}</p>
            <p class="mb-0 pb-0">Price: ${parseFloat(props.buy_value_usd / props.amount).toFixed(8)}</p>
            <p class="fs-6 mb-0 pb-0">{new Date(props.buy_datetime * 1000).toLocaleString()}
              <a href={"https://explorer.radixdlt.com/#/transactions/" + props.buy_tx_hash} target="_blank">
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2"/>
              </a>
            </p>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <p class="mb-0 pb-0">$ {parseFloat(props.sell_value_usd).toFixed(2)}</p>
            <p class="mb-0 pb-0">Price: ${parseFloat(props.sell_value_usd / props.amount).toFixed(8)}</p>
            <p class="fs-6 mb-0 pb-0">{new Date(props.sell_datetime * 1000).toLocaleString()}
              <a href={"https://explorer.radixdlt.com/#/transactions/" + props.sell_tx_hash} target="_blank">
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2"/>
              </a>
            </p>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <p class="mb-0 pb-0">$ {parseFloat(props.fees_value_usd).toFixed(2)}</p>
          </span>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Token</th>
              <th className="border-bottom">Profit / Loss</th>
              <th className="border-bottom">Buy</th>
              <th className="border-bottom">Sell</th>
              <th className="border-bottom">Fees</th>
            </tr>
          </thead>
          <tbody>
            {props.countPageItems > 0 ? props.items.map(t => <TableRow key={t.id}{...t}/>) : <tr/> }
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            { props.countTotalItems < 30 ? <p></p>
                : <Pagination className="mb-2 mb-lg-0">
                {
                  props.pages.map(page => (
                    page == props.nrActivePage 
                    ? <Pagination.Item active>{page}</Pagination.Item>
                    : <Pagination.Item onClick={() => {props.pageClickHandler(page)}}>{page}</Pagination.Item>
                  ))
                }
              </Pagination>
            }
          </Nav>
          <small className="fw-bold">
            Showing <b>{props.countPageItems}</b> out of <b>{props.countTotalItems}</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};
