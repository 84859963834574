
import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faMoneyBillWave, faCoins, faFile, faChartPie, faExchangeAlt, faHandHoldingUsd, faBalanceScale, faTimes, faUpload, faGlasses, faSearchDollar } from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Nav, NavDropdown, Badge, Image, Button, Dropdown, Accordion, Navbar, Collapse } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import RupineLogoIcon from "../assets/img/rupine_logo_1.png";
import GemHiveLogoIcon from "../assets/img/rupine_logo.png";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import NavbarToggle from "@themesberg/react-bootstrap/lib/esm/NavbarToggle";
import NavbarCollapse from "@themesberg/react-bootstrap/lib/esm/NavbarCollapse";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const [taxNavOpen, setTaxNavOpen] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem title="Gem Hive" link={Routes.Presentation.path} image={GemHiveLogoIcon} />

              <NavItem title="Overview" link={Routes.DashboardOverview.path} icon={faChartPie} />
              <NavItem title="Balances" icon={faBalanceScale} link={Routes.Balances.path} />
              <NavItem title="Transactions" icon={faHandHoldingUsd} link={Routes.Transactions.path} />
              <NavItem title="Staking" icon={faCoins} link={Routes.Staking.path} />
              {/*
              <NavItem title="Lending" icon={faMoneyBillWave} link={Routes.Lending.path} />
              */}
              
              <Dropdown.Divider className="my-3 border-white" />
              <NavItem title="Trades" icon={faExchangeAlt} link={Routes.TaxTrades.path} />
              <NavItem title="Positions" icon={faHistory} link={Routes.TaxFifos.path} />
              <NavItem title="Tax Report" icon={faFile} link={Routes.TaxReport.path} />
              {/*
              <Dropdown.Divider className="my-3 border-white" />
              {/*
              <NavItem title="Imports" icon={faUpload} link={Routes.UploadCenter.path} />
              */}
              <Dropdown.Divider className="my-3 border-white" />

              <a href="https://twitter.com/RUPINE_Labs"><FontAwesomeIcon icon={faTwitter}/> @RUPINE_Labs</a>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
