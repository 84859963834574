import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFilter, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, Card } from '@themesberg/react-bootstrap';

import useRupineAPI from "../../data/RupineAPI";

export const WalletEdit = (props) => {

  // loading component
  const { getDataAuthorized } = useRupineAPI();

  return (
    <>
            <Form>
              <Row>
                <Form.Label>Address</Form.Label>
                <p><img src={props.icon_url} width={25} className="mb-3" /> {props.public_address_short}</p> 
              </Row>
              <Row className="align-items-center">
                <Col md={6} className="mb-3">
                  <Form.Group id="public_address_name">
                    <Form.Label>Name</Form.Label>
                    {
                      props.isWalletEdit ? <Form.Control required type="text" placeholder={props.public_address_name} name='public_address_name' onChange={props.handleInputChange}/>
                                         : <Form.Control required type="text" placeholder={props.public_address_name} disabled/>
                    }
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="is_business">
                    <Form.Label>Company owned</Form.Label>
                    {
                      props.isWalletEdit ? 
                      <Form.Select defaultValue={props.is_business} name='is_business' onChange={props.handleInputChange}>
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                      </Form.Select>
                      : 
                      <Form.Select defaultValue={props.is_business} disabled>
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                      </Form.Select>
                    }
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="comment">
                    <Form.Label>Comment</Form.Label>
                    {
                      props.isWalletEdit ? <Form.Control required type="text" placeholder={props.comment} name='comment' onChange={props.handleInputChange}/>
                                         : <Form.Control required type="text" placeholder={props.comment} disabled/>
                    }
                  </Form.Group>
                </Col>
              </Row>
            </Form>
    </>
  );
};
