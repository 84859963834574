import React, { useState, useEffect, useMemo } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";
import { UserContext } from '../UserContext';

// pages
import Presentation from "./Presentation";
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Staking from "./Staking";
import Lending from "./Lending";
import TaxTrades from "./TaxTrades";
import TaxFifos from "./TaxFifos";
import TaxReport from "./TaxReport";
import Impressum from "./Impressum";
import Profile from "./Profile";
import PrivacyPolicy from "./PrivacyPolicy";
import Balances from "./Balances";
import UploadCenter from "./UploadCenter";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";


const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  const [loading, setLoading] = useState('Loading...');
  const [user, setUser] = useState(null);
  const value = useMemo(() => ({ user, setUser, loading, setLoading }), [user, setUser, loading, setLoading]);


  return (
      <Route {...rest} render={props => (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />

          <UserContext.Provider value={value}>
            <main className="content">
              <Navbar />
              <Component {...props} />
              <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
            </main>
          </UserContext.Provider>
        </>
      )}
      />
  );
};

export default () => (
  <Switch>
    {/* Left-overs from Themesberg Template */}
    <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
    <RouteWithSidebar exact path={Routes.Balances.path} component={Balances} />
    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
    <RouteWithSidebar exact path={Routes.Staking.path} component={Staking} />
    <RouteWithSidebar exact path={Routes.Lending.path} component={Lending} />
    <RouteWithSidebar exact path={Routes.UploadCenter.path} component={UploadCenter} />
    <RouteWithSidebar exact path={Routes.TaxTrades.path} component={TaxTrades} />
    <RouteWithSidebar exact path={Routes.TaxFifos.path} component={TaxFifos} />
    <RouteWithSidebar exact path={Routes.TaxReport.path} component={TaxReport} />
    <RouteWithSidebar exact path={Routes.Impressum.path} component={Impressum} />
    <RouteWithSidebar exact path={Routes.Profile.path} component={Profile} />
    <RouteWithSidebar exact path={Routes.PrivacyPolicy.path} component={PrivacyPolicy} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
