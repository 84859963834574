import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFilter, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Spinner } from '@themesberg/react-bootstrap';

import { TransactionsTable } from "./tables/TransactionsTable";
import useRupineAPI from "../data/RupineAPI";
import useHasuraAPI from "../data/HasuraAPI";

export default () => {

  // loading component
  const { getWalletData, getTransactionData, insertTransactionManipulation } = useHasuraAPI();

  // State for wallet drop down
  const [wallets, setWallets] = useState([]);
  const [countWallets, setCountWallets] = useState(1);
  const [selectedWallet, setSelectedWallet] = useState(1);
  const [selectedWalletStr, setSelectedWalletStr] = useState(1);

  // States for Balance Table
  const [items, setItems] = useState([]);
  const [countTotalItems, setCountTotalItems] = useState(0);
  const [countPageItems, setCountPageItems] = useState([0]);
  const [pages, setPages] = useState(0);
  const [nrActivePage, setNrActivePage] = useState(1);

  // Loading state
  const [loadingTx, setLoadingTx] = useState(true);

  useEffect(() => {

    async function fetchData() {

      // Set wallet data
      var walletData = await getWalletData(30, 0)

      if (walletData == null)
      {
        setLoadingTx(false)
        return;
      }

      if (walletData.address_account.length > 0)
      {

        await LoadTransactionsData(walletData.address_account[0].public_address, 1)
        
        setSelectedWallet(walletData.address_account[0].public_address)
        setSelectedWalletStr(walletData.address_account[0].public_address_name + ' (' + 
                            walletData.address_account[0].public_address_short + ')')
      }

      setWallets(walletData.address_account)
      setCountWallets(walletData.address_account.length)
      setLoadingTx(false)
    }

    fetchData();
    return () => {};
  }, []);

  const LoadTransactionsData = async (public_address, p) => 
  {
    console.log('LoadTransactionsData')

      // set wallet text for display
      try{
        var wallet = wallets.filter(item => item.public_address === public_address);
        setSelectedWalletStr(wallet[0].public_address_name + ' (' +  wallet[0].public_address_short + ')');
      } catch(e){}

      // Reload
      setNrActivePage(p);

      var limit = 30;
      var offset = (p-1) * limit;
      var data = await getTransactionData(limit, offset, public_address);

      if (data == null)
      {
        setLoadingTx(false)
        return;
      }

      setItems(data.transaction);
      setCountPageItems(data.transaction.length);
      setCountTotalItems(data.aggregate.count);
      var _pageCount = Math.ceil(data.aggregate.count / limit);
      let _pages = [];
      let start_index = 1;
      let end_index = _pageCount;
      if (p > 2){
        start_index = p - 2;
      } 
      if (_pageCount > (p+2)){
        end_index = p + 2;
      }
      if(end_index - start_index < 4){
        end_index = start_index + 4;
      }


      for (let i = start_index; i <= end_index; i++) {
          _pages = _pages.concat(i);
      }
      setPages(_pages)
      setLoadingTx(false)
  }

  const pageClickHandler = async(page) => {
    setLoadingTx(true);
    LoadTransactionsData(selectedWallet, page);
  }

  const onSelectHandler = async(eventKey, event) => {
    //console.log('onSelectHandler')
    //console.log(eventKey)
    setLoadingTx(true);
    setSelectedWallet(eventKey)
    LoadTransactionsData(eventKey, 1);
  }

  const onSaveTransactionEdit = (txToEdit, editFields) => {
    //console.log('onSaveTransactionEdit')

    var success = insertTransactionManipulation(
        txToEdit.transaction_id
      , parseFloat(editFields.amount_send_value_usd)
      , parseFloat(editFields.amount_recv_value_usd)
      , parseFloat(editFields.amount_fee_value_usd)
      , txToEdit.account_name
      , editFields.category)

    if (success)
    {

      items.forEach(element => {
        if(element.transaction_id == txToEdit.transaction_id)
        {
          element.tx_type_no = editFields.category
          element.amount_send_value_usd = editFields.amount_send_value_usd
          element.amount_recv_value_usd = editFields.amount_recv_value_usd
          element.amount_fee_value_usd = editFields.amount_fee_value_usd
        }
      });
    }

  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Transactions</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          {/*}
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
          </ButtonGroup>
          */}
        </div>
      </div>

      <div>
        { loadingTx ? 
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
          :
          countWallets > 1 ?
            <Dropdown as={ButtonGroup} onSelect={(eventKey, event) => onSelectHandler(eventKey, event)} >
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faFilter} /> {selectedWalletStr}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-md dropdown-menu-right">
              {/*wallets.map((w) => <Dropdown.Item onClick={walletClickHandler(w.public_address)} className="fw-bold text-dark">{w.public_address_name} ({w.public_address_short})</Dropdown.Item>)*/}
              {wallets.map((w) => <Dropdown.Item className="fw-bold text-dark" eventKey={w.public_address}>{w.public_address_name} ({w.public_address_short})</Dropdown.Item>)}
              </Dropdown.Menu>
            </Dropdown>
          : <p></p>
          }
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          {/*
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
            */}
        </Row>
      </div>
      { loadingTx ? <p></p>
      :
        <TransactionsTable 
          items={items} 
          countTotalItems={countTotalItems}
          countPageItems={countPageItems}
          pages={pages} 
          nrActivePage={nrActivePage}
          pageClickHandler={pageClickHandler}
          saveTxEditHandler={onSaveTransactionEdit}/>
      }
    </>
  );
};
